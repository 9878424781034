import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import './scss/reset.scss'
import './scss/fonts.scss'
import { createRoot, hydrateRoot } from 'react-dom/client';

const rootElement = document.getElementById('body'); 

// Проверяем, есть ли в корневом элементе дочерние узлы для определения, нужно ли использовать гидратацию
if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);
} else {
  // Создаём корень и рендерим приложение в него
  const root = createRoot(rootElement); // Инициализируем корневой элемент для React 18
  root.render(<App />);
}

// ReactDOM.render(
 
//   <App></App>,
//   document.getElementById('body')
// )


