export const plantContentMurm = {
  helmet: {
    title: 'Мобильный комплекс очистки масел - Промтех',
    description: 'Предлагаем мобильную установку комплексной очистки трансформаторного масла на базе автомобиля Камаз и установок очистки и регенации для восстановления трансформаторных и турбинных масел'
  },
  Init: {
    title: 'Мобильный комплекс очистки',
    text: `Комплекс на базе автомобиля КАМАЗ с прицепом. Может включать любую установку из 
          ассортимента: очистка 4 м3/ч, ЭФ, регенерация 1 м3/ч, а также встроенную химическую лабораторию с поверенным оборудованием`,
    points: [
      'Дистанция до 1500 км',
      'Производительность до 6 м3/ч',
      'Время развертывания 45-60 минут',
      'Габариты без прицепа 6058x2438x2591',
    ]
  },
  Property: {
    title: 'Ключевые качества',
    properties: [
      'Комбинация установок очистки и регенерации разного типа',
      'Встроенная экспресс-лаборатория',
      'Возможность перемещения комлекса по объектам заказчика'
    ]
  },
  Character: {
     title: 'Технические характеристики',
     table1: {
      title: 'Показатели установки',
      columns: [
        '№ п/п',
        'Наименование',
        'Единица измерения',
        'Значение'
      ],
      mainRows: [
        [
          'Метод очистки и регенерации масел',
          'Па',
          `Электростатическая очистка, фильтрация, 
          вакуумное испарение и адсорбция.`
        ],
        [
          'Метод очистки и регенерации масел',
          'Па',
          `Электростатическая очистка, фильтрация, 
          вакуумное испарение и адсорбция.`
        ],
        [
          'Метод очистки и регенерации масел',
          'Па',
          `Электростатическая очистка, фильтрация, 
          вакуумное испарение и адсорбция.`
        ],
      ],
      subRows: [
        [
          'Метод очистки и регенерации масел',
          'Па',
          `Электростатическая очистка, фильтрация, 
          вакуумное испарение и адсорбция.`
        ],
        [
          'Метод очистки и регенерации масел',
          'Па',
          `Электростатическая очистка, фильтрация, 
          вакуумное испарение и адсорбция.`
        ],
        [
          'Метод очистки и регенерации масел',
          'Па',
          `Электростатическая очистка, фильтрация, 
          вакуумное испарение и адсорбция.`
        ],
      ]

     },
     table2: {
      title: 'Сертификация итогов',
      columns: [
        '№ п/п',
        'Наименование',
        'Единица измерения',
        'Значение'
      ],
      mainRows: [
        [
          'Метод очистки и регенерации масел',
          'Па',
          `Электростатическая очистка, фильтрация, 
          вакуумное испарение и адсорбция.`
        ],
        [
          'Метод очистки и регенерации масел',
          'Па',
          `Электростатическая очистка, фильтрация, 
          вакуумное испарение и адсорбция.`
        ],
        [
          'Метод очистки и регенерации масел',
          'Па',
          `Электростатическая очистка, фильтрация, 
          вакуумное испарение и адсорбция.`
        ],
      ],
      subRows: [
        [
          'Метод очистки и регенерации масел',
          'Па',
          `Электростатическая очистка, фильтрация, 
          вакуумное испарение и адсорбция.`
        ],
        [
          'Метод очистки и регенерации масел',
          'Па',
          `Электростатическая очистка, фильтрация, 
          вакуумное испарение и адсорбция.`
        ],
        [
          'Метод очистки и регенерации масел',
          'Па',
          `Электростатическая очистка, фильтрация, 
          вакуумное испарение и адсорбция.`
        ],
      ]

     }
  }
}