import React, {useEffect, useState} from 'react'
import { Helmet } from "react-helmet";
import './service.style.scss'
import { Link } from "react-router-dom"
import { serviceContent } from './service.content';

export const Service = (props) => {
  const content = serviceContent[props.page]
  window.scrollTo(0, 0);
  function showFooter() {
    document.querySelector('.footer').style.display = "flex"
  }
  setTimeout(() => {
    showFooter()
  }, 300);
  return (
    <div className='service'>
      <Helmet>
        <title>{content.title}</title>
        <meta name="description" content={content.description} />
        <link rel="canonical" href={`https://promtech-company.ru/services/${props.page}/`}/>
      </Helmet>
      <h1>{content.h1}</h1>
      <p>{content.info}</p>
      <h2>{content.aboutTitle}</h2>
      <p>{content.about}</p>
      {content.tableTitle && 
      <>
      <p>{content.tableTitle}</p>
      <table>
      <tr>
        <th>Удаляемое вещество</th>
        <th>Метод удаления</th>
        </tr>
        <tr>
          <td>Вода</td>
          <td>Вакуумная сушка, адсорбционная сушка</td>
        </tr>
        <tr>
          <td>Газы</td>
          <td>Вакуумная дегазация, адсорбция</td>
        </tr>
        <tr>
          <td>Твердые частицы</td>
          <td>Фильтрация, центрифугирование</td>
        </tr>
        <tr>
          <td>Кислоты</td>
          <td>Нейтрализация, адсорбция</td>
        </tr>
        <tr>
          <td>Оксиды</td>
          <td>Адсорбция, катализ</td>
        </tr>
      </table>
      </>
      }
      <h2>{content.methodsTitle}</h2>
      <p>{content.methods}</p>
      <div className="request">
        <div className="request-info">Если вам необходим данный вид услуги, вы можете связаться с нами для обсуждения проведения работ</div>
        <div className="request-button" onClick={props.showForm}>Запросить коммерческое предложение</div>
      </div> 
    </div>
  )
}