import React, { useEffect, useState, useRef } from 'react'
import { Link } from "react-router-dom"
import './opener.style.scss'
import $ from 'jquery'
import {usePrevious} from 'react-use';
import { homeContent as content } from './../../home/home.content';
import InputRange from 'react-input-range';

export default function (props) {

  const openerBody = useRef(null)
  const openerWrap = useRef(null)

  useEffect(() => {
    if(openerBody.current && openerWrap.current && document.documentElement.clientWidth < 768){
      if(props.type !== 'close'){
        openerWrap.current.style.display = 'block'
        openerBody.current.style.transition = `transform 0.5s cubic-bezier(0.77, 0, 0.175, 1)`
        openerWrap.current.style.backgroundColor = 'rgba(0, 0, 0, 0.4)'
        openerBody.current.style.transform  = 'translateY(0)'
        document.querySelector('body').style.overflowY =  "hidden"
      } else {
        openerWrap.current.style.backgroundColor = 'rgba(0, 0, 0, 0)'
        openerBody.current.style.transform  = 'translateY(100%)'
        setTimeout(() => {
          openerWrap.current.style.display = 'none'
          document.querySelector('body').style.overflowY =  "scroll"
        }, 500);
      }
    }

  }, [props.type]) 

  const sendMessage = () => {
    props.setOpenerType('close')
    let formData = new FormData();
    // let inputFiles = files
    // console.log(inputFiles)

    let name = document.querySelector('#nameMob').value
    let email = document.querySelector('#emailMob').value
    let phone = document.querySelector('#phoneMob').value
    let text = document.querySelector('#textMob').value

    // let filesInp = document.querySelector("#file").files
    // console.log(filesInp)
  
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('text', text);
    // formData.append('files', inputFiles);

    $.ajax({
      url: 'https://mail.regeneration-oil.ru/mail.php', 
      dataType: 'text', 
      cache: false,
      contentType: false,
      processData: false,
      data: formData,                         
      type: 'post',
      beforeSend: function(){
      },
      success: function(){
        
      }
   });

    document.querySelector('#nameMob').value = ''
    document.querySelector('#phoneMob').value = ''
    document.querySelector('#emailMob').value = ''
    document.querySelector('#textMob').value = ''
  }

  const prevType = usePrevious(props.type);
       
  return (
    <div className="menu-open" ref={openerWrap}>
      <div className="menu-wrapper" onClick={()=>props.setOpenerType('close')}> 
        <div className="menu-body" 
          ref={openerBody} 
          onClick={(event)=>{
            event.stopPropagation();
            props.setOpenerType(props.type)
          }}
        >
           {(props.type === 'menu' ||  prevType === 'menu') &&
           <>
            <div 
              className="close" 
              onClick={(event)=>{
                event.stopPropagation();
                props.setOpenerType('close')
              }}/>
            <div className="content">
              <div className="links">
                <div className="link"><Link to={'/'}>Главная страница</Link></div>
                <div className="link"><Link to={'/company/'}>О компании</Link></div>
                <div className="link"><Link to={'/plants/'}>Каталог установок</Link></div>
                <div className="link"><Link to={'/plants/moim/'}>Установка очистки</Link></div>
                <div className="link"><Link to={'/plants/ksor/'}>Установка регенерации</Link></div>
                <div className="link"><Link to={'/contact/'}>Контакты</Link></div>
                <div className="link"><Link to={'/archive/'}>Документы</Link></div>
              </div>
              <div className="contacts">
                <div className="phone">{props.phoneNumber}</div>
                <div className="email">info@promtech-company.ru</div>
              </div>
            </div>
            </>
          }
          {
          (props.type === 'form' ||  prevType === 'form') &&
            <>
              <div 
                className="close close-form" 
                onClick={(event)=>{
                  event.stopPropagation();
                  props.setOpenerType('close')
                }}
              />
              <div className="content">
              <div className="form-mobile">
                <div className="field">
                  <div className="name">Ваше имя:</div>
                  <input id="nameMob" type="text" placeholder="Иван Иванов" />
                </div>
                <div className="field">
                  <div className="name">Ваш email:</div>
                  <input id="emailMob" type="text" placeholder="info@gmail.com" />
                </div>
                <div className="field">
                  <div className="name">Ваш телефон:</div>
                  <input id="phoneMob" type="text" placeholder="+7 (495) 900-70-70" />
                </div>
                <div className="field">
                  <div className="name">Сообщение:</div>
                  <textarea id="textMob" placeholder='Текст вашего сообщения пишите здесь' />
                </div>
                <div className="button" onClick={(event)=>{
                  event.stopPropagation();
                  sendMessage()
                }}><div className="figure"/><div>Отправить</div></div>
              </div>
              </div>
            </>
          }
          {
          (props.type === 'calculate' ||  prevType === 'calculate') &&
            <>
              <div 
                className="close close-form" 
                onClick={(event)=>{
                  event.stopPropagation();
                  props.setOpenerType('close')
                }}
              />
              <div className="content">
              <div className="calculating">
              <div className="radio-groups">
                <div className="radio-group">
                  <div className="radio-title">{content.calculator.calculating.title1}</div>
                  <div className="wrap flex">
                    {props.cycles.map(cycle => {
                      return(
                        <div className="radio">
                          {cycle.active ? 
                              <div className="radio-button active"/> 
                              : 
                              <div className="radio-button" onClick={()=> {props.changeCycle(cycle.name)}}/>
                            }
                          {cycle.name}
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="radio-group">
                  <div className="radio-title">{content.calculator.calculating.title2}</div>
                  <div className="wrap">
                    {props.cleanClasses.map(cleanClass => {
                        return(
                          <div className="radio">
                            {cleanClass.active ? 
                              <div className="radio-button active"/> 
                              : 
                              <div className="radio-button" onClick={()=> {props.changeCleanClasses(cleanClass.name)}}/>
                            }
                            {cleanClass.name}
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
              <div className="slider-value">
                <div className="slider-title">{content.calculator.calculating.distance}</div>
                <div className="slider-body">
                  <InputRange
                    step={100}
                    minValue={props.minDistance}
                    maxValue={props.maxDistance}
                    formatLabel={distance => `${distance} км`}
                    value={props.distance}
                    onChange={distance => props.setDistance(distance)}
                  />
                </div>
              </div>
              <div className="slider-value">
                <div className="slider-title">{content.calculator.calculating.volume}</div>
                <div className="slider-body">
                <InputRange
                  minValue={props.minVolume}
                  maxValue={props.maxVolume}
                  formatLabel={volume => `${volume} тонн`}
                  value={props.volume}
                  onChange={volume => props.setVolume(volume)}
                />
                </div>
              </div>
                <div className="button" onClick={(event)=>{
                    event.stopPropagation();
                    props.setOpenerType('close')
                    document.querySelector('.result-value').scrollIntoView({block: "center", behavior: "smooth"});
                  }}>
                    <div className="figure"/>
                    <div>Применить</div>
                </div>
              </div>
              </div>
            </>
          }

        </div>
      </div>
    </div>
  )
}
