export const plantsContent = {
  helmet: {
    title: 'Маслоочистительные и дегазационные установки - Промтех',
    description: 'Установки для очистки, сушки, дегазации и регенерации трансформаторных и турбинных масел из отечественных комплектующих',
    canonical: 'https://promtech-company.ru/plants/'
  },
  About: {
    title: "Установки очистки и регенерации",
    text1: `Установки для высокопроизводительной очистки и регенерации трансформаторных и турбинных масел из отечественных комплектующих`,
    text2: `Доступные операции: фильтрация, дегазация, осушка и регенерация через сорбент`
  },
  ServiseOr: {
    title: "Услуга или покупка",
    // text1: `Целевой продукт нашей компании - услуга по регенерации масла. 
    //         Но из-за некоторых условий нам невыгодно совершать сделку. 
    //         Ниже перечислены условия, при которых выгодно заказать услугу вместо покупки`,
    text1: `При необходимости единоразовой очистки рекомендуется проведение выездных работ по восстановлению масла на вашем объекте. 
            Предварительно сопоставив удаленность и объем отработанного масла с нижеприведенными: `,
    tezisGroup: [
      [ 
        "Москва и Московская область, объем более 5 тонн",
        "До 2500 км от Москвы, объем более 100 тонн"

      ],
      [
        "В пределах 1000 км от Москвы, объем более 25 тонн",
        "Условия являются примерными, все варианты обсуждаемы в частном порядке"
      ]
    ],
    text2: "В случаях большей удаленности и часто пополняемых объемов отработанного масла рекомендуем приобрести установку на ваш выбор: "
  },
  Catalog: [
    [
      {
        title: "Установка очистки 1 м3/ч",
        info: `Малогабаритная маслоочиститальная установка фильтрации, осушки и дегазации с производительностью до 1 м3/ч`
      },
      {
        title: "Установка очистки 2 м3/ч",
        info: `Универсальная установка очистки масла с оптимальным балансом между ценой и производительностью до 2 м3/ч`
      }
    ],
    [
      {
        title: "Установка очистки 4 м3/ч",
        info: `Наиболее высокопроизводительная установка из всего ассортимента. Выполняет дегазацию, осушку и фильтрацию со скоростью до 6 м3/ч`
      },
      {
        title: "Установка очистки",
        info: `Сохранила функционал 4 м3/ч и приобрела модуль электрофизической  очистки. Лучше всего подойдет для атомных электростанций`
      }
    ],
    [
      {
        title: "Установка регенерации 1 м3/ч",
        info: `Обладает блоком адсорбентов для процесса регенерации, что позволяет вернуть физико-химические свойства к заводскому состоянию в соответствии с ГОСТ и СТО`
      },
      {
        title: "Мобильный комплекс очистки",
        info: `Мобильный комплекс очистки: автомобиль КАМАЗ с прицепом, установки очистки 4 м3/ч и регенерации, а также встроенная хим. лаборатория`
      }
    ]
  ]
}