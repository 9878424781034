import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate  } from "react-router-dom";
import Home from './components/home/home.page'
import Analysis from './components/analysis/analysis.page'
import Company from './components/company/company.page'
import Plants from './components/Plants/Plants';
import Plant from './components/Plants/Plant';
import Contact from './components/Contact/Contact';
import Techno from './components/techno/techno.page';
import Archive from './components/archive/archive.page';
// import NotFound from './components/404/not-found.page';
import Form from './components/Units/Form/Form'
import Navbar from './components/Units/Navbar/Navbar';
import Opener from './components/Units/Opener/opener.component';
import Footer from './components/Units/Footer/Footer';
// import QAWrap from './components/qa-system/QAWrap';
import { targetFunc } from './ya-metrics';
import { Service } from './components/services/service.page';
import { NewService } from './components/new-service/new-service.page';
import $ from 'jquery'





function App() {

  const [phoneNumber, setPhoneNumber] = useState('+7 (495) 363-7129')



  const [requestedPlant, setRequestedPlant] = useState('')
  const [requestedCall, setRequestedCall] = useState(false)

  

  const plantRequest = (name) => {
    setRequestedPlant(name)
    showForm()
  }

  const callRequest = () => {
    setRequestedCall(true)
    showForm()
  }
  
  function closeForm() {
    document.querySelector('.form').style.display =  "none"
    document.querySelector('body').style.overflowY =  "scroll"
    document.querySelector('body').style.paddingRight =  "0px"

    document.querySelector('#name').value = ''
    document.querySelector('#phone').value = ''
    document.querySelector('#email').value = ''
    setRequestedCall(false)
    // document.querySelector('#text').defaultValue = ''
  }
  
  function showForm() {
    document.querySelector('.form').style.display =  "block"
    document.querySelector('body').style.overflowY =  "hidden"
    document.querySelector('body').style.paddingRight =  "7px"
  }

  const isPrerender = navigator.userAgent === "ReactSnap";

  useEffect(()=>{
    if(
      // (document.location.href.includes("/techno/") ||
      document.location.href.includes("/economic/") 
      // document.location.href.includes("/analysis/") ) 
      && 
      (document.documentElement.clientWidth < 768 )
      && !isPrerender
    ){
      document.location.href = "/"
    }
  }, [])

  const [openerType, setOpenerType] = useState('close')

  const [cycles, setCycles] = useState([
    {name: 'Очистка', active: true},
    {name: 'Регенерация', active: false},
  ])

  const [cleanClasses, setCleanClasses] = useState([
    {name: 'от 5 до 8', active: false},
    {name: 'от 8 до 12', active: true},
    {name: 'ниже 12', active: false}
  ])

  const [distance, setDistance] = useState(0)
  const [minDistance] = useState(0)
  const [maxDistance] = useState(2500)

  const [volume, setVolume] = useState(5)
  const [minVolume] = useState(5)
  const [maxVolume] = useState(150)

  useEffect(() => {
    let newCost = 0;
    if(cycles[1].active){
      newCost+= 40
    } else {
      newCost+= 12
    }

    if(cleanClasses[1].active){
      newCost += 8
    } else if (cleanClasses[2].active) {
      newCost += 17
    }

    newCost += 15 * distance/maxDistance
    newCost -= 10 * volume/maxVolume

    newCost = parseInt(newCost)

    setCost(newCost)

  }, [cycles, cleanClasses, distance, volume])

  // Время пользователя на сайте
  useEffect(() => {
      setTimeout(() => {
        targetFunc('time_left')
      }, 60 * 1000)
      setTimeout(() => {
        targetFunc('long_time_left')
      }, 5 * 60 * 1000)
  }, [])
 
  

  const changeCycle = (name) => {
    let copyCycles = [...cycles]
    copyCycles.forEach(cycle => {
      cycle.active = false
    })
    copyCycles.forEach(cycle => {
      if(cycle.name === name) cycle.active = true
    })
    setCycles(copyCycles)
  }

  const changeCleanClasses = (name) => {
    let copyCycles = [...cleanClasses]
    copyCycles.forEach(cycle => {
      cycle.active = false
    })
    copyCycles.forEach(cycle => {
      if(cycle.name === name) cycle.active = true
    })
    setCleanClasses(copyCycles)
  }

  const openQACallback = (type, number) => {
    setOpenArticleQA({id: 14, type, number})
  }

  // function getIPFromAmazon() {
  //   fetch('https://checkip.amazonaws.com/')
  //     .then((res) => res.text())
  //     .then((data) => console.log(data));
  // }

  const [blur, setBlur] = useState(true)
  const [ip, setIP] = useState('0.0.0.0')



  const banUser = (matter) => {
    let formData = new FormData();
    formData.append('ip', ip);
    formData.append('matter', matter);
    if(ip !== '0.0.0.0'){
      $.ajax({
        url: 'https://mail.regeneration-oil.ru/updateList.php', 
        dataType: 'json', // Ожидаем JSON ответ
        cache: false,
        contentType: false,
        processData: false,
        data: formData,                         
        type: 'post',
        crossDomain: true,
        success: function(response) {
            // Обработка успешного ответа
            if (response.success) {
              setTimeout(() => {
                 window.location.reload();
              }, 500);
            } 
        },
    });
    
    }
  }

  useEffect(() => {
    // getIPFromAmazon()
    // document.addEventListener('contextmenu', event => event.preventDefault());

    document.addEventListener('keydown', function(event) {
      if (event.key === 'F12' || 
         (event.ctrlKey && event.shiftKey && (event.key === 'I' || event.key === 'J')) || 
         (event.ctrlKey && event.shiftKey && (event.key === 'Ш' || event.key === 'О')) || 
         (event.ctrlKey && event.shiftKey && (event.key.toLowerCase() === 'i' || event.key.toLowerCase() === 'j')) || 
         (event.ctrlKey && event.shiftKey && (event.key.toLowerCase() === 'ш' || event.key.toLowerCase() === 'о')) ||
         (event.ctrlKey && event.key === 'U') ||
         (event.ctrlKey && event.key === 'Г') ||
         (event.ctrlKey && event.key.toLowerCase() === 'u') ||
         (event.ctrlKey && event.key.toLowerCase() === 'г') ||
         (event.ctrlKey && (event.key === 's' || event.key === 'ы' || event.key === 'p' || event.key === 'з') || event.keyCode == 44)){
          event.preventDefault();
          if(ip !== '0.0.0.0'){
            banUser('"Попытка открыть средства разработчика, сохранить страницу или сделать скриншот"')
          }
      }
    });

    // document.addEventListener('keydown', function(event) {
    //   if (event.key === 'Meta') {
    //       console.log('Нажата клавиша Windows');
    //   }
    // });


    // window.addEventListener('blur', () => {
    //   if(window.location.pathname !== '/contact/'){
    //     setBlur(true)
    //   } else if(blur){
    //     setBlur(false)
    //   }
    // });
  
    // window.addEventListener('focus', () => {
    //     setBlur(false)
    //     clearClipboard() 
    // });

    // async function clearClipboard() {
    //   try {
    //     // Попытка чтения содержимого буфера обмена (может быть ограничено браузером)
    //     let text
    //     if (typeof navigator.clipboard.readText === 'function') {
    //       text = await navigator.clipboard.readText();
    //     } else {
    //        text = ''
    //     }
    //     if (text) {
    //       // Если в буфере есть текст, пытаемся его очистить
    //       await navigator.clipboard.writeText('');
    //       // Рекурсивно вызываем функцию, чтобы убедиться, что буфер действительно пуст
    //       await clearClipboard();
    //     } else {
    //       await navigator.clipboard.writeText('');
    //     }
    //   } catch (err) {
    //     // console.error('Ошибка при работе с буфером обмена:', err);
    //   }
    // }

      
  

  }, [ip])

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => setIP(data.ip))
    .catch(error => console.log('Ошибка:', error));
  }, [])
  

  const [country, setCountry] = useState('No country')
  useEffect(() => {
    if(country != 'RU' && country != 'BY' && country != 'US' && country != 'No country'){
        if(country === 'UZ' || country === 'US' || country === 'GB' || country == 'DE' || country == 'FR' || country == 'NL'){
          if(ip !== '0.0.0.0'){
            banUser('"Не российский регион"')
          }
        }
        setTimeout(() => {
          window.location.href = "https://google.com/";
       }, 700);
    } else {
      setBlur(false)
    }
  }, [country, ip])

 
  

  useEffect(() => {
    if(ip !== '0.0.0.0'){
      fetch(`https://ipinfo.io/${ip}?token=2eec4c9732e679`)
        .then(response => response.json())
        .then(data => {
          let formData = new FormData();
          formData.append('ip', ip);
          formData.append('region', data.region);
          formData.append('city', data.city);
          formData.append('country', data.country);
          formData.append('ref', document.referrer || 'Источник неизвестен')
          setCountry(data.country)

          // Действия с полученными данными
          $.ajax({
            url: 'https://mail.regeneration-oil.ru/ip.php', 
            dataType: 'text', 
            cache: false,
            contentType: false,
            processData: false,
            data: formData,                         
            type: 'post',
            crossDomain: true,
            beforeSend: function(){
            },
          });
          
    })
        .catch(error => console.log('Ошибка:', error));
    }
      
  }, [ip])



  
 
  

  const [openArticleQA, setOpenArticleQA] = useState(undefined)
 
  const [cost, setCost] = useState(40)

  const servicePages = [
    'drying',
    'filtration',
    'degasification',
    'regeneration',
    'cleaning',

    // 'replacement',
    // 'filling',

    'testing',
    'hga',
  ]

  useEffect(() => {
    document.querySelector('.App').style.display = 'block';
  }, [])

  useEffect(() => {
    console.log(blur)
  }, [blur])
  
  

  return (
    <div className="App">
        {!isPrerender ?
          <div className="push">
            <div className="push-wrap">
              Ваше сообщение успешно доставлено
            </div>
          </div> :<></>
        }
        <Router>
          {!isPrerender ? <Form closeForm={closeForm} requestedPlant={requestedPlant} requestedCall={requestedCall}/> :  <></> }
          <div className={`global-blur blur-${blur}`}></div>
          <Navbar changeNumber={setPhoneNumber} phoneNumber={phoneNumber} setOpenerType={setOpenerType} callRequest={callRequest}/>
          {/* {!isPrerender ? <QAWrap openArticle={openArticleQA}></QAWrap> : <></>} */}
          {!isPrerender ? <Opener 
            phoneNumber={phoneNumber} 
            type={openerType} 
            setOpenerType={setOpenerType}
            cleanClasses={cleanClasses}
            cycles={cycles}
            changeCycle={changeCycle}
            changeCleanClasses={changeCleanClasses}
            maxDistance={maxDistance}
            minDistance={minDistance}
            distance={distance}
            setDistance={setDistance}
            minVolume={minVolume}
            maxVolume={maxVolume}
            volume={volume}
            setVolume={setVolume}
            cost={cost}
          /> : <></>}
          <Routes> 
            {/* <Route path="/" element={
              document.documentElement.clientWidth < 768 ? <Main showForm={showForm}/> : <Home showForm={showForm}/>
            } /> */}
            <Route path="/" element={
              <Home 
                setOpenerType={setOpenerType}
                cleanClasses={cleanClasses}
                cycles={cycles}
                changeCycle={changeCycle}
                changeCleanClasses={changeCleanClasses}
                maxDistance={maxDistance}
                minDistance={minDistance}
                distance={distance}
                setDistance={setDistance}
                minVolume={minVolume}
                maxVolume={maxVolume}
                volume={volume}
                setVolume={setVolume}
                cost={cost}
                showForm={showForm}
              />} 
            />         
            <Route path="/company/" element={<Company/>} />            
            <Route path="/analysis/" element={<Analysis openQACallback={openQACallback}/>} />            
            <Route path="/plants/" element={<Plants/>} />
            <Route path="/plants/plant-1.0/" element={<Plant name='plant1' plantRequest={() => plantRequest('1 м3/ч')}/>}/>
            <Route path="/plants/plant-2.0/" element={<Plant name='plant2' plantRequest={() => plantRequest('2 м3/ч')}/>}/>
            <Route path="/plants/plant-4.0/" element={<Plant name='plant4' plantRequest={() => plantRequest('4 м3/ч')}/>}/>
            <Route path="/plants/plant-ef/" element={<Plant name='plantEf' plantRequest={() => plantRequest('очистки')}/>}/>
            <Route path="/plants/plant-reg/" element={<Plant name='plantReg' plantRequest={() => plantRequest('регенерации')}/>}/>
            <Route path="/plants/plant-mob/" element={<Plant name='plantMob' plantRequest={() => plantRequest('"Мобильный комплекс"')} />}/>
            <Route path="/contact/" element={<Contact showForm={showForm} phoneNumber={phoneNumber} setOpenerType={setOpenerType}/>} />
            <Route path="/techno/" element={<Techno/>} />
            {/* <Route path="/economic" element={<Economic/>} /> */}
            <Route path="/archive/" element={<Archive/>} />
            <Route path={`/services/`} element={<Service page={'drying'} showForm={showForm}/>} />
            <Route path={`/services/replacement/`} element={<NewService page={'replacement'} showForm={showForm}/>} />
            <Route path={`/services/filling/`} element={<NewService page={'filling'} showForm={showForm}/>} />
            <Route path={`/services/topping/`} element={<NewService page={'topping'} showForm={showForm}/>} />

            <Route path={`/trans/`} element={<NewService type={'trans'} page={'to'} showForm={showForm}/>} />
            <Route path={`/trans/to/`} element={<NewService type={'trans'} page={'to'} showForm={showForm}/>} />
            <Route path={`/trans/current/`} element={<NewService type={'trans'} page={'current'} showForm={showForm}/>} />
            <Route path={`/trans/capital/`} element={<NewService type={'trans'} page={'capital'} showForm={showForm}/>} />
            <Route path={`/trans/install/`} element={<NewService type={'trans'} page={'install'} showForm={showForm}/>} />

            {servicePages.map(page => {
              return (
                <Route path={`/services/${page}/`} element={<Service page={page} showForm={showForm}/>} />
              )
            })}
            {/* <Route path="*" element={<NotFound/>} /> */}
          </Routes>
          <Footer showForm={showForm} phoneNumber={phoneNumber} setOpenerType={setOpenerType}/>
        </Router>
    </div>
  );
}





export default App;
