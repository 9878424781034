export const analysisContent = {
  helmet: {
    title: 'Анализ трансформаторного масла - Промтех',
    description: 'Предлагаем полный лабораторный анализ трансформаторного масла с точными результатами и конкурентоспособными ценами. Профессиональные услуги от аккредитованной лаборатории анализа масла.',
    canonical: 'https://promtech-company.ru/analysis/'
  },
  About: {
    title: "Лабораторный анализ масла",
    text1: `Проведение полного и экспресс-анализа эксплуатационного масла по необходимым показателям для дальнейшего восстановления согласно стандартам ГОСТ и СТО`,
    // text2: `Доступные операции: фильтрация, дегазация, осушка и регенерация через сорбент`
  },
  Catalog: {
    title: "Выбор анализов",
    switch: {'tf': 'Трансформаторное', 'gydr': 'Гидравлическое', 'turb': 'Турбинное'},
    subtitles: ['Анализы базовой очистки', 'Анализы полной регенерации', 'Дополнительные анализы']
  },
  Form: {
    title: 'Оформление заявки',
    subtitles: ['Тип масла', 'Выбранные анализы'],
    buttonClose: 'Очистить выбор',

    info: `Заполните форму обратной связи, чтобы мы могли связаться с
           вами для более детального обсуждения. Если не знаете, какие 
           анализы выбрать, то отправьте заявку с типом масла - обсудим на звонке.`,
    name: 'Ваше имя',
    email: 'Email',
    phone: 'Телефон',
    submit: 'Отправить заявку'
  }
}
