import React, {useEffect} from 'react'
import './contact.scss'
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";

import logoImg from '../../media/img/Promtech-logo-short.png'
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom"
import { ReactComponent as IconFile } from '../../media/icons/file.svg';
import { useClipboard } from 'use-clipboard-copy';
import { pageTargets,targetFunc } from '../../ya-metrics';






export default function Contact(props) {

  const clipboard = useClipboard();

  const copyMail = () => {
    clipboard.copy('info@promtech-company.ru')
    // alert('Почта скопирована')
  }

  const copyPhone = () => {
    clipboard.copy(props.phoneNumber)
    // alert('Телефон скопирован')
  }

  window.scrollTo(0, 0);
  function closeFooter() {
    document.querySelector('.footer').style.display = "none"
    // if(document.querySelector('.mobile-footer'))document.querySelector('.mobile-footer').style.display = "none"
  }

  setTimeout(() => {
    closeFooter()
    // document.querySelector('.contacts__map').innerHTML = `<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Af8857bd38c31c2abc760dbcc4352f93af21260fef1450b0989fd162d7cc60dfe&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>`

  }, 300);

  useEffect(() => {
    document.querySelector('#contactsPhone').addEventListener('mousedown', ()=>{targetFunc(pageTargets.contact.phone)});
    document.querySelector('#contactsMail').addEventListener('mousedown', ()=>{targetFunc(pageTargets.contact.mail)});
    document.querySelector('#contactsAddress').addEventListener('mousedown', ()=>{targetFunc(pageTargets.contact.address)});
  }, [])


  return (
    <div>
      <Helmet>
        <title>{'Контактная информация - Промтех'}</title>
        <meta name="description" content={'Обратная связь и контактная информация по выполнению работ очистки и регенерации промышленных масел'} />
        <link rel="canonical" href={'https://promtech-company.ru/contact/'}/>
      </Helmet>
      <div class="contacts__content">
        <div class="contacts__info wow animate__animated animate__fadeIn" data-wow-duration="0.8s">
          <div class="contacts__title"><h1>Контактная информация</h1></div>
          <div className="contact-row">
            <div class="contacts__phone">
              <h2>Телефон</h2>
              {/* <p>Гор. +7 (495) 985-8303</p> */}
              <p id="contactsPhone" onClick={()=> {
                // copyPhone()
              }} ref={clipboard.target}>{props.phoneNumber}</p>
            </div>
            <div class="contacts__email">
              <h2>Почта</h2>
              <p id="contactsMail" onClick={()=> {
                // copyMail()
              }} ref={clipboard.target} >info@promtech-company.ru</p>
            </div>
          </div>
          <div className="contact-row">
          <div class="contacts__address">
            <h2>Офис компании</h2>

            <p id="contactsAddress"> 115409, РФ, г. Москва, Каширское шоссе, д.49</p>
          </div>
          <a href="../files/Компания_ПРОМТЕХ.pdf" target="_blank">
            <div class="contacts__card">
              <h2> Карточка <br />компании</h2>
              <IconFile className="contacts__file" />
            </div>
          </a>
          </div>
          <p className='test_info_test'>*Выполнение работ по всей территории РФ</p>
          <div class="contacts__button" onClick={() => { 
            targetFunc(pageTargets.contact.formOpen);
            if(document.documentElement.clientWidth > 768) props.showForm() 
            else props.setOpenerType('form')
          }}>
            Написать нам
          </div>
        </div>
        <div class="contacts__map wow animate__animated animate__fadeIn" data-wow-duration="0.8s">
        <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A16807c405790dc5ce1a00f266db5b603b7e8a130597ac01d25e15de88e16b2c6&amp;source=constructor" width="100%" height="100%" frameborder="0"></iframe></div>
      </div>

      <div class="contacts__footer">
        <div class="footer__container">
          <div class="footer__logo">
            <Link to="/">
              <img src={logoImg} alt="" class="footer__logo_img"></img>
            </Link>
          </div>
          <div class="footer__copyright">
            ©2024 <span>ООО "Компания ПРОМТЕХ"</span>
          </div>
        </div>
      </div>
    </div>
  )
}
