import React, {useEffect, useState, useRef} from 'react'
import './home.style.scss'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { homeContent as content } from './home.content';
import {adpt, mb} from '../../helpers/adaptive'
import { ReactComponent as EnterIcon } from './assets/arrow-enter.svg';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { pageTargets,targetFunc } from '../../ya-metrics';
// import addSlider from './helpers/gorizontal-scroll'


export default function Home(props) {
  let clientHeight = document.documentElement.clientHeight
  let aboveElement, block, scrollBlock, value

  // Функция для сдвига циклов
//   useEffect(() => {
//     if(document.documentElement.clientWidth > 768){
//     block = document.querySelector('.cycles-wrap')
//     scrollBlock = document.querySelector('.sticky-wrap')
//       window.addEventListener('scroll', function (e) {
//         aboveElement = block.getBoundingClientRect().top
//         if(aboveElement < 0 && aboveElement > -(block.offsetHeight - clientHeight)){
//           value = block.getBoundingClientRect().top*1/-(block.offsetHeight - clientHeight)*100
//           scrollBlock.style.transform = `translateX(-${value/2}%)`
//         }
//         if(aboveElement > 0){
//           scrollBlock.style.transform = `translateX(0%)`
//         }
//         if(aboveElement < -(block.offsetHeight - clientHeight)){
//           scrollBlock.style.transform = `translateX(-50%)`
//         }
//       })
//     }
//   }, [])

  const location = useLocation();
  

  useEffect(() => {
    window.scrollTo(0, 0);
    if(location.hash === '#main_tf'){
      setBack(0)
      setView(0)
    }else if (location.hash === '#main_tb'){
      setBack(1)
      setView(1)
    }
  }, [location])

  const [back, setBack] = useState(location.hash === '#tb' ? 1 : 0)
  const [view, setView] = useState(location.hash === '#tb' ? 1 : 0)

  const figure = useRef(null)
  const transformSwitch = useRef(null)
  const turbineSwitch = useRef(null)

  useEffect(() => {
    if(view === 0){
      figure.current.style.transform=`translateX(0px)`
      transformSwitch.current.style.color=`#FFFFFA`
      turbineSwitch.current.style.color=`black`
    } else {
      if(document.documentElement.clientWidth > 768){
        figure.current.style.transform=`translateX(${adpt(259)}px)`
      } else figure.current.style.transform=`translateX(${mb(186)}px)`
      transformSwitch.current.style.color=`black`
      turbineSwitch.current.style.color=`#FFFFFA`
    }
  }, [view])

 
  let timeout = 12000

  const mainBackground = useRef(null)
  const toggleBackground = () => {
    mainBackground.current.classList.toggle('changeBackground');
    setTimeout(() => {
      toggleBackground()
    }, timeout);
  }
  useEffect(() => {
    setTimeout(() => {
      toggleBackground()
    }, timeout);
  }, [])

  console.log(location)


  // if(location.hash === '#tf' || location.hash === '#tb'){
  //   // eslint-disable-next-line no-restricted-globals
  //   history.pushState('', document.title, window.location.pathname)
  // }

  // window.scrollTo(0, 0);
  function showFooter() {
    document.querySelector('.footer').style.display = 'flex'
    if (document.documentElement.clientWidth < 1000) {
      document.querySelector('.footer').style.display = 'block'
    }
  }

  setTimeout(() => {
    showFooter()
  }, 300);

  const calc = (val) => {
    if (document.documentElement.clientWidth < 1920) {
      return val * 1024 / 1920 + (val - val * 1024 / 1920) * ((document.documentElement.clientWidth - 1024) / (1920 - 1024))
    } else return val
  }

  let navigate = useNavigate();
  

  const isPrerender = navigator.userAgent === "ReactSnap";


  
  return (
    <>
    <div className='new-home'>
      <Helmet>
        <title>{content.helmet.title}</title>
        <meta name="description" content={content.helmet.description} />
        <link rel="canonical" href={content.helmet.canonical}/>
      </Helmet>
      <div className="main">
        <div className="header-line"/>
        <div className="content__container">
          <div ref={mainBackground} className={`main-background ${back===0 ? 'changeBackground' : ''}`}>
            <div className="shadow"/>
            <div className="tezis">
              <div className="logo"><img src={require('./../../media/img/Promtech-logo.png')} alt=""></img></div>
              <h1 className="tezis-info">{content.main.tezis}</h1>
              <Link to={`/company/`} onClick={()=>targetFunc(pageTargets.main.moreCompanyButton)}><div className="tezis-button">Подробнее</div></Link>
            </div>
            <div className="line"></div>
          </div>
        </div>
      </div>
      <div className="activity">
        <div className="content__container">
          <div className="activity-info">
            <h3>{content.activity.title}</h3>
            <div className="activity-text">
              <p dangerouslySetInnerHTML={{__html: content.activity.text1}}/>
              {/* <p>{content.activity.text2}</p> */}
            </div>
          </div>
        </div>
        <div className="activity-cycles">
          <div className="cycles-wrap">
            <div className="cycles-body">
              <div className="circles"></div>
              <div className="circles circles-2"></div>
              <div className="sticky-wrap">
              {/* <div className="cycle-wrap">
                  <div className="cycle  full">
                    <div className="circle"></div>
                    <div className="circle circle-2"></div>
                    <div className="cycle-title">Анализ показателей масла</div>
                    <div className="cycle-operations">
                      <div className="operation">Класс промышленной чистоты</div>
                      <div className="operation">Содержание воды и газа</div>
                      <div className="operation">Пробойное напряжение </div>
                      <div className="operation">Кислотное число</div>
                    </div>
                    <div className="cycle-info">Проведение полного и экспресс-анализа эксплуатационного масла по необходимым показателям для дальнейшего восстановления согласно стандартам ГОСТ и СТО </div>
                    <div className="cycle-img QA"></div>
                  </div>
                </div> */}
                <div className="cycle-wrap">
                  <div className="cycle basic">
                    <div className="circle"></div>
                    <div className="circle circle-2"></div>
                    <div className="cycle-title">{content.cycles.basic.title}</div>
                    <div className="cycle-operations">{content.cycles.basic.operations.map(opeation=>{
                      return(
                        <div className="operation">{opeation}</div>
                      )
                    })}</div>
                    <div className="cycle-info">{content.cycles.basic.info}</div>
                    <div className="cycle-img basic"></div>
                  </div>
                </div>
                <div className="cycle-wrap">
                  <div className="cycle full">
                    <div className="circle"></div>
                    <div className="circle circle-2"></div>
                    <div className="cycle-title">{content.cycles.full.title}</div>
                    <div className="cycle-operations">{content.cycles.full.operations.map(opeation=>{
                      return(
                        <div className="operation">{opeation}</div>
                      )
                    })}</div>
                    <div className="cycle-info">{content.cycles.full.info}</div>
                    <div className="cycle-img full"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="qualities">
      <div className="content__container">
        <div className="qualities-title"><h3>{content.qualities.title}</h3></div>
        <div className="qualities-swicth" onClick={()=>targetFunc(pageTargets.main.switchType)}>
          <div className="wrap">
            <div className="figure" ref={figure}></div>
            <div className="switch-buttons">
              <div className="switch-button" ref={transformSwitch} onClick={()=>{setView(0)}}>{content.qualities.switch[0]}</div>
              <div className="switch-button" ref={turbineSwitch} onClick={()=>{setView(1)}}>{content.qualities.switch[1]}</div>
            </div>
          </div>
        </div>
        <div className="qualities-view">
          <div className="info">
            <div className="view-title"><h2>{content.qualities.views[view].title}</h2></div>
            <div className="view-info"><p>{content.qualities.views[view].info}</p></div>
            <div className="table-wrap">
              <div 
                className="open-table-button" 
                onClick={()=>{
                  document.querySelector('.view-table').style.display = 'flex'
                  document.querySelector('.open-table-button').style.display = 'none'
                  document.querySelector('.close-table-button').style.display = 'block'
                }}
              >
                Показать параметры
              </div>
              <div 
                className="close-table-button"
                onClick={()=>{
                  document.querySelector('.view-table').style.display = 'none'
                  document.querySelector('.open-table-button').style.display = 'block'
                  document.querySelector('.close-table-button').style.display = 'none'
                }}
              >
                Закрыть таблицу
              </div>
              <div className={`view-table table-${view}`}>
              {content.qualities.views[view].table.map(column=>{
                return(
                  <div className="column">
                    <div className="column-head">{column.title}</div>
                    <div className="points">
                    {column.info.map(point=>{
                      return(
                        <div className="point" dangerouslySetInnerHTML={{__html: point}} />
                      )
                    })}
                    </div>
                  </div>
                )
              })}
              </div>
            </div>
          </div>
          <div className="img"><img src={require(`./assets/${content.qualities.views[view].img}`)} alt="" /></div>
        </div>
      </div>
      </div>
      <div className="calculator">
        <div className="content__container">
          <div className="line"></div>
          <div className="calculator-title"><h3>{content.calculator.title}</h3></div>
          <div className="calculator-info"><p>{content.calculator.info}.<span> Вы так же можете воспользоваться настройкой <span>калькулятора</span>.</span></p></div>
          <div className="wrap">
            <div className="calculate-mobile" onClick={()=>props.setOpenerType('calculate')}>Открыть настройки</div>
            <div className="calculating" onClick={()=>targetFunc(pageTargets.main.calculatorAcitivity)}>
              <div className="radio-groups">
                <div className="radio-group">
                  <div className="radio-title">{content.calculator.calculating.title1}</div>
                  <div className="wrap flex">
                    {props.cycles.map(cycle => {
                      return(
                        <div className="radio">
                          {cycle.active ? 
                              <div className="radio-button active"/> 
                              : 
                              <div className="radio-button" onClick={()=> {props.changeCycle(cycle.name)}}/>
                            }
                          <div className='radio-name' onClick={()=> {props.changeCycle(cycle.name)}}>{cycle.name}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="radio-group">
                  <div className="radio-title">{content.calculator.calculating.title2}</div>
                  <div className="wrap">
                    {props.cleanClasses.map(cleanClass => {
                        return(
                          <div className="radio">
                            {cleanClass.active ? 
                              <div className="radio-button active"/> 
                              : 
                              <div className="radio-button" onClick={()=> {props.changeCleanClasses(cleanClass.name)}}/>
                            }
                            <div className='radio-name' onClick={()=> {props.changeCleanClasses(cleanClass.name)}}>{cleanClass.name}</div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
              <div className="slider-value">
                <div className="slider-title">{content.calculator.calculating.distance}</div>
                <div className="slider-body">
                  <InputRange
                    step={100}
                    minValue={props.minDistance}
                    maxValue={props.maxDistance}
                    formatLabel={distance => `${distance} км`}
                    value={props.distance}
                    onChange={distance => props.setDistance(distance)}
                  />
                </div>
              </div>
              <div className="slider-value">
                <div className="slider-title">{content.calculator.calculating.volume}</div>
                <div className="slider-body">
                <InputRange
                  minValue={props.minVolume}
                  maxValue={props.maxVolume}
                  formatLabel={volume => `${volume} тонн`}
                  value={props.volume}
                  onChange={volume => props.setVolume(volume)}
                />
                </div>
              </div>
            </div>
            <div className="result">
              <div className="result-title">{content.calculator.result.title}</div>
              <div className="result-value">
               {props.cost}% <span>{content.calculator.result.info}</span>
              </div>
              <div 
                className="result-button" 
                onClick={() => { 
                  props.showForm();
                  targetFunc(pageTargets.main.calculatorFormButton)
                }}
              >
                {content.calculator.result.button}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="plants">
      <div className="content__container">
        <div className="wrap">
        <div className="info">
          <div className="info-title"><h3>{content.plants.title}</h3></div>
          <div className="info-text1"><p>{content.plants.text1}</p></div>
          <div className="info-text2"><p>{content.plants.text2}</p></div>
          <div className="points">
            {content.plants.points.map(point=>{
              return(
                <div className="point">
                  <div className="dot"></div>
                  {point}
                </div>
              )
            })}
          </div>
        </div>
        <div className="img">
        <div 
          className="button targetButton" 
          onClick={()=> {
            targetFunc(pageTargets.main.plantsButton);
            if(document.documentElement.clientWidth > 768){
              document.querySelector('.targetButton').style.width = `${calc(687+87)}px`
              setTimeout(() => {
                // window.location.href='/plants#catalog'
                navigate('/plants/');
              }, 300);
            } else navigate('/plants/');
          }}
        >
          <div className="icon">
            <EnterIcon/>
          </div>
        </div>
        </div>
        </div>
        {/* window. location. href */}
        {/* /plants#catalog */}
      </div>
      </div>

      { !isPrerender ?
      <div className="newMap">
      <div class="newMap-body">
      <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A16807c405790dc5ce1a00f266db5b603b7e8a130597ac01d25e15de88e16b2c6&amp;source=constructor" width="100%" height="100%" frameborder="0"></iframe>

      </div>
      </div> 
      : <></>}
    </div>
    { !isPrerender ? <div className="mobileAtten">Мобильная версия сайта РМ находится в разработке. <br/> Приносим извинения за временные неудобства</div> : <></>}
    </>
  )
}
