export const plantContentKsor = {
  helmet: {
    title: 'Установка регенерации трансформаторного масла 1 м3/ч - Промтех',
    description: 'Установка регенерации масла для эффективного восстановления трансформаторного масла. Повышайте производительность трансформаторов с нашим надежным оборудованием. Ознакомьтесь с опциями и ценами.'
  },
  Init: {
    title: 'Установка регенерации масла 1 м3/ч',
    text: `В установке 1 м3/ч применены регенерируемые электрофильтры для очистки от механических примесей и продуктов старения, а также
    сорбционные материалы многократного использования`,
    points: [
      'Регенерация 1 м3/ч',
      'Очистка и осушка 4 м3/ч',
      'Габариты 5400х2430х2340',
      'Объем адсорберов 1700 литров',
    ]
  },
  Property: {
    title: 'Ключевые качества',
    properties: [
      'Регенерация физико-химических свойств масел',
      'Восстановление кислотного числа и тангенса диэлектрических потерь',
      'Мобильность, при размещении установки в прицепе'
    ]
  },
  Character: {
     title: 'Технические характеристики',
     table1: {
      title: 'Показатели установки',
      columns: [
        '№ п/п',
        'Наименование',
        'Единица измерения',
        'Значение'
      ],
      mainRows: [
        [
          'Метод очистки и регенерации масел',
          '—',
          `Электростатическая очистка, фильтрация, вакуумное испарение и адсорбция`
        ],
        [
          'Используемый сорбент',
          '—',
          `Оксид алюминия`
        ],
        [
          'Максимально допустимое рабочее давление на выходе',
          'МПа',
          `0,4`
        ],
        [
          'Минимальное давление на входе',
          'МПа',
          `0,03`
        ],
      ],
      subRows: [
        [
          'Максимально  давление на выходе',
          'МПа',
          `0,2`
        ],
        [
          'Температура масла на входе',
          '°С',
          `+0 ÷ +60`
        ],
        [
          'Уровень шума, не более',
          'dВ',
          `80`
        ],
        [
          'Класс безопасности по ОПБ-88/97',
          '—',
          `4`
        ],
        [
          'Вид климатического исполнения установки по ГОСТ 15150',
          '—',
          `УХЛ4`
        ],
      ]

     },
     table2: {
      title: 'Сертификация итогов',
      columns: [
        '№ п/п',
        'Наименование',
        'Единица измерения',
        'Значение'
      ],
      mainRows: [
        [
          'Максимальное влагосодержание на входе по ГОСТ Р 54281-2010',
          '%',
          `не более 0,5`
        ],
        [
          'Достигаемое влагосодержание на выходе по ГОСТ Р 54281-2010',
          '%',
          `Не более 0,001`
        ],
        [
          'Класс чистоты до очистки, по ГОСТ 17216, не более',
          'класс',
          `16`
        ],
        [
          'Класс чистоты после очистки, по ГОСТ 17216, не более',
          'класс',
          `8`
        ],
      ],
      subRows: [
        [
          'Кислотное число по ГОСТ 5985 до регенерации, (Турбинное масло)',
          'мг КОН/г',
          `Не более 0,30`
        ],
        [
          'Кислотное число по ГОСТ 5985 после регенерации, (Турбинное масло)',
          'мг КОН/г',
          `Не более 0,15`
        ],
        [
          'Кислотное число по ГОСТ 5985 до регенерации, (Трансформаторное масло)',
          'мг КОН/г',
          `Не более 0,15`
        ],
        [
          'Кислотное число по ГОСТ 5985 после регенерации, (Трансформаторное масло)',
          'мг КОН/г',
          `Не более 0,02`
        ],
        [
          'Пробивное напряжение до регенерации (Трансформаторное масло)',
          'кВ',
          `-`
        ],
        [
          'Пробивное напряжение до регенерации (Трансформаторное масло)',
          'кВ',
          `Не менее 65`
        ],
      ]

     }
  }
}