export const targetFunc = (id) => {
  var ee = setInterval(function () {
    if (typeof window.ym != 'undefined') {
          console.log(id)
          window.ym(89252994,'reachGoal', id)
        clearInterval(ee);
    } else {
      console.log('Метрика не инициализирована');
    }
  }, 500);
}


export const pageTargets = {
  main: {
    moreCompanyButton: 'main_moreCompanyButton',
    switchType: 'main_switchType',
    calculatorAcitivity: 'main_calculatorAcitivity',
    calculatorFormButton: 'main_calculatorFormButton',
    plantsButton: 'main_plantsButton'
  },

  analysis: {
    tabs: 'analysis_tabs',
    selected_1: 'analysis_selected_1',
    selected_2_5: 'analysis_selected_2_5',
    selected_more_5: 'analysis_selected_more_5',
    formSend: 'analysis_formSend'
  },

  contact: {
    phone: 'contact_phone',
    mail: 'contact_mail',
    address: 'contact_address',
    card: 'contact_card',
    formOpen: 'contact_formOpen',
  },

  navbar: {
    currentPage: 'navbar_currentPage',
    burger: 'navbar_burger',
    phone: 'navbar_phone',
    formOpen: 'navbar_formOpen'
  }, 

  footer: {
    company: 'footer_company',
    phone: 'footer_phone',
    mail: 'footer_mail',
    formOpen: 'footer_formOpen',
  },


  form: {
    sendForm: 'form_sendForm'
  },

  QA: {
    openQA: 'QA_openQA'
  }
}