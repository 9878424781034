import React, {useEffect, useRef, useState} from 'react'
import './analysis.style.scss'
import { analysisContent as content } from './analysis.content';
import { catalogDefault } from './analysis.catalog';
import './analysis.style.scss'
import { Helmet } from "react-helmet";
import {adpt, mb} from '../../helpers/adaptive'
import { ReactComponent as QAIcon } from './assets/QAIcon.svg';
import { ReactComponent as PlusIcon } from './assets/plus.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import $ from 'jquery'
import { pageTargets,targetFunc } from '../../ya-metrics';




export default function Analysis(props) {

  function showFooter() {
    document.querySelector('.footer').style.display = 'flex'
    if (document.documentElement.clientWidth < 1000) {
      document.querySelector('.footer').style.display = 'block'
    }
  }

  
  useEffect(() => {
    let blur = document.querySelector('.info-img-blur')
    blur.style.transform = `translateX(${calc(520)}px)`
    window.scrollTo(0, 0);
    setTimeout(() => {
      showFooter()
    }, 300);

  }, [])

  const calc = (val) => {
    if (document.documentElement.clientWidth < 1920) {
      return val * 1024 / 1920 + (val - val * 1024 / 1920) * ((document.documentElement.clientWidth - 1024) / (1920 - 1024))
    } else return val
  }

  const openQA = (type, number)=>{
    props.openQACallback(type, number)
  }

  const figure = useRef(null)
  const [view, setView] = useState('tf')

  const transformSwitch = useRef(null)
  const gydravSwitch = useRef(null)
  const turbineSwitch = useRef(null)

  useEffect(() => {
    if(view === 'tf'){
      figure.current.style.transform=`translateX(0px)`
      transformSwitch.current.style.color=`#FFFFFA`
      figure.current.style.width=`${adpt(294)}px`
      gydravSwitch.current.style.color=`black`
      turbineSwitch.current.style.color=`black`
    } else if(view === 'gydr'){
        if(document.documentElement.clientWidth > 768){
          figure.current.style.transform=`translateX(${adpt(275)}px)`
        } else figure.current.style.transform=`translateX(${mb(186)}px)`
        figure.current.style.width=`${adpt(260)}px`
        transformSwitch.current.style.color=`black`
        gydravSwitch.current.style.color=`#FFFFFA`
        turbineSwitch.current.style.color=`black`
    } else {
      if(document.documentElement.clientWidth > 768){
        figure.current.style.transform=`translateX(${adpt(519)}px)`
      } else figure.current.style.transform=`translateX(${mb(186)}px)`
      figure.current.style.width=`${adpt(225)}px`
      transformSwitch.current.style.color=`black`
      gydravSwitch.current.style.color=`black`
      turbineSwitch.current.style.color=`#FFFFFA`
    }
  }, [view])

  const [catalog, setCatalog] = useState(catalogDefault)

  const switchAnalys = (viewType, part, name, add) => {
    let catalogCopy = JSON.parse(JSON.stringify(catalog))
    catalogCopy[viewType][part].map(analys=>{
      if(analys.name === name){
        if(add){
          analys.checked = true
        } else {
          analys.checked = false
        }
      }
    })
    setCatalog(catalogCopy)
  }

  const clearAll = () => {
    let catalogCopy = JSON.parse(JSON.stringify(catalog))
    typeList.map(type=> {
      catalogCopy[view][type].map(analys=>{
        if(analys.checked){
          analys.checked = false
        }
      })
    })
    setCatalog(catalogCopy)
  }

  const typeList = ['base', 'full', 'more']
  const [selectedSize, setSelectedSize] = useState(0)

  useEffect(() => {
    let size = 0
    typeList.map(type=>{
      catalog[view][type].map(analys=>{
        if(analys.checked) {
          size++
        }
      })
    })
    setSelectedSize(size)
  }, [catalog, view])

  // declensionsFromQuantity(tableInstance.selectedFlatRows.length, [
  //   t('modules.incidents.table.incident'),
  //   t('modules.incidents.table.incidents'),
  //   t('modules.incidents.table.incidents_'),
  // ])

  const declensionsFromQuantity = (value, words) => {
    value = Math.abs(value) % 100;
    var num = value % 10;
    if (value > 10 && value < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if (num === 1) return words[0];
    return words[2];
  };
  


  const sendMail = () => {

    let formData = new FormData();

    let name = document.querySelector('#analysName').value
    let phone = document.querySelector('#analysPhone').value
    let email = document.querySelector('#analysEmail').value

    if(!name) {
      alert('Введите ваше имя')
    } else if(!phone) {
      alert('Введите телефон')
    } else if(!email){
      alert('Введите почту')
    } else {
  
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);

    


    // formData.append('type', content.Catalog.switch[view])
    // formData.append('list', JSON.stringify(backet))
    const text = `Анализ масла <br/> Тип масла: ${content.Catalog.switch[view]}<br/><br/>Список:<br/>${backet.join('<br/>')}`;
    formData.append('text', text)

    targetFunc(pageTargets.analysis.formSend)


    $.ajax({
      url: 'https://mail.regeneration-oil.ru/mail.php', 
      dataType: 'text', 
      cache: false,
      contentType: false,
      processData: false,
      data: formData,                         
      type: 'post',
      crossDomain: true,
      beforeSend: function(){
      },
      success: function(data){
        console.log(data)
      }
   });
    document.querySelector('.push').style.display =  "block"
    document.querySelector('body').style.overflowY =  "hidden"
    document.querySelector('body').style.paddingRight =  "7px"
    setTimeout(() => {
      document.querySelector('.push').style.opacity =  1
      setTimeout(() => {
        document.querySelector('.push').style.opacity =  0
        document.querySelector('body').style.overflowY =  "scroll"
        document.querySelector('body').style.paddingRight =  "0px"
        setTimeout(() => {
          document.querySelector('.push').style.display =  "none"
        }, 5000);
      }, 2000);
    }, 100);
    document.querySelector('#analysName').value = ''
    document.querySelector('#analysPhone').value = ''
    document.querySelector('#analysEmail').value = ''
  }
    
  }

  const [backet, setBacket] = useState([])

  useEffect(() => {
    if(backet.length === 1) {
      targetFunc(pageTargets.analysis.selected_1)
    } else if (backet.length >= 2 &&  backet.length <= 5) {
      targetFunc(pageTargets.analysis.selected_2_5)
    } else if (backet.length > 5){
      targetFunc(pageTargets.analysis.selected_more_5)
    }
  }, [backet])
  

  useEffect(() => {
    let selectedAnalysis = []
    typeList.map(type => {
      catalog[view][type].map(analys=>{
        if(analys.checked) selectedAnalysis.push(analys.name)
      })
    })
    setBacket([...selectedAnalysis])
  }, [catalog, view])

  useEffect(() => {
    if(selectedSize > 0 && document.querySelector('.selectedPush')){
      document.querySelector('.selectedPush').style.transform = `translate3d(-50%, 0%, 0)`
      document.querySelector('.selectedPush').style.opacity =  1
    } else if(selectedSize === 0 && document.querySelector('.selectedPush')){
      document.querySelector('.selectedPush').style.opacity =  0
      document.querySelector('.selectedPush').style.transform = `translate3d(-50%, 300%, 0)`
    }

   
  }, [selectedSize])



  // Получаем нужный элемент
var element = document.querySelector('#targetTitle');

var Visible = function (target) {
  // Все позиции элемента
  var targetPosition = {
      top: window.scrollY + target.getBoundingClientRect().top,
      left: window.scrollX + target.getBoundingClientRect().left,
      right: window.scrollX + target.getBoundingClientRect().right,
      bottom: window.scrollY + target.getBoundingClientRect().bottom
    },
    // Получаем позиции окна
    windowPosition = {
      top: window.scrollY,
      left: window.scrollX,
      right: window.scrollX + document.documentElement.clientWidth,
      bottom: window.scrollY + document.documentElement.clientHeight
    };

  if (targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
    targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
    targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
    targetPosition.left < windowPosition.right) { // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
    if( document.querySelector('.selectedPush')){
        document.querySelector('.selectedPush').style.opacity =  0
        document.querySelector('.selectedPush').style.transform = `translate3d(-50%, 300%, 0)`
    }
  } else {
    if(selectedSize > 0 && document.querySelector('.selectedPush')){
      document.querySelector('.selectedPush').style.transform = `translate3d(-50%, 0%, 0)`
      document.querySelector('.selectedPush').style.opacity =  1
    }
  };
};

window.addEventListener('scroll', function() {
  Visible (element);
});

const goToForm = () => {
    let target = undefined
    let count = 0
    while(!target && count<100){
        target = document.querySelector(`#analysName`)
        count++
    }
   
    if(target){
        target.scrollIntoView({block: "center", behavior: "smooth"})
    }
}


  
  
  



  return (
    // <div className='analysis'>
    //   <h1>Страница анализов</h1>
    //   <button onClick={()=>{openQA('tf', 'anchor1')}}>Кнопка</button>
    // </div>
    <div className="analysis">
      <Helmet>
        <title>{content.helmet.title}</title>
        <meta name="description" content={content.helmet.description} />
        <link rel="canonical" href={content.helmet.canonical}/>
      </Helmet>
      <div className="Warm">
        <div className="content__container">
          <div className="Warm-body">
            <div className="About">
              <div className="about-title wow animate__animated animate__fadeIn" data-wow-duration="0.8s"><h1>{content.About.title}</h1></div>
              <div className="about-info wow animate__animated animate__fadeIn" data-wow-duration="0.8s">
                <div className="info-text">
                  <p>{content.About.text1}</p>
                </div>
                <div className="info-img">
                  <div className="info-img-blur"></div>
                  <img src={require('./assets/about-analysis.jpg')} alt="" />
                </div>
              </div>
            </div>
            <div className="Catalog">
               <div className="title"><h3>{content.Catalog.title}</h3></div>
                <div className="swicth" onClick={()=>{targetFunc(pageTargets.analysis.tabs)}}>
                  <div className="wrap">
                    <div className="figure" ref={figure}></div>
                    <div className="switch-buttons">
                      <div className="switch-button" ref={transformSwitch} onClick={()=>{setView('tf')}}>{content.Catalog.switch.tf}</div>
                      <div className="switch-button" ref={gydravSwitch} onClick={()=>{setView('gydr')}}>{content.Catalog.switch.gydr}</div>
                      <div className="switch-button" ref={turbineSwitch} onClick={()=>{setView('turb')}}>{content.Catalog.switch.turb}</div>
                    </div>
                  </div>
                </div>

                <div className="part base">
                  <div className="subtitle">{content.Catalog.subtitles[0]}</div>
                  {catalog[view].base.map(analys => {
                    return(
                      <div className='analys base'>
                        <QAIcon className="icon" onClick={()=>{openQA('tf', analys.link)}}/>
                        <div className="name">{analys.name}</div>
                        {!analys.checked ? 
                          <div className="button button-add" onClick={()=>switchAnalys(view, 'base', analys.name, true)}><PlusIcon className="plus-icon"/></div>
                          :
                          <div className="button button-close" onClick={()=>switchAnalys(view, 'base', analys.name, false)}><CloseIcon className="close-icon"/></div>
                        }
                      </div>
                    )
                  })}
                </div>
                <div className="part full">
                  <div className="subtitle">{content.Catalog.subtitles[1]}</div>
                  {catalog[view].full.map(analys => {
                    return(
                      <div className='analys full'>
                        <QAIcon className="icon" onClick={()=>{openQA('tf', analys.link)}}/>
                        <div className="name">{analys.name}</div>
                        {!analys.checked ? 
                          <div className="button button-add" onClick={()=>switchAnalys(view, 'full', analys.name, true)}><PlusIcon className="plus-icon"/></div>
                          :
                          <div className="button button-close" onClick={()=>switchAnalys(view, 'full', analys.name, false)}><CloseIcon className="close-icon"/></div>
                        }
                      </div>
                    )
                  })}
                </div>
                <div className="part more">
                <div className="subtitle">{content.Catalog.subtitles[2]}</div>
                  {catalog[view].more.map(analys => {
                    return(
                      <div className='analys more'>
                        <QAIcon className="icon" onClick={()=>{openQA('tf', analys.link)}}/>
                        <div className="name">{analys.name}</div>
                        {!analys.checked ? 
                          <div className="button button-add" onClick={()=>switchAnalys(view, 'more', analys.name, true)}><PlusIcon className="plus-icon"/></div>
                          :
                          <div className="button button-close" onClick={()=>switchAnalys(view, 'more', analys.name, false)}><CloseIcon className="close-icon"/></div>
                        }
                      </div>
                    )
                  })}
                </div>

            </div>
            <div className="Form">
              <div className="title" id='targetTitle'><h3>{content.Form.title}</h3></div>
              <div className="content">
                <div className="selected">
                  <div className="inner">
                    <div className="subtitle">{content.Form.subtitles[0]}</div>
                    <div className="selected-type">{content.Catalog.switch[view]}</div>
                    <div className="subtitle">{content.Form.subtitles[1]}</div>
                    <div className="selected-list">
                    {selectedSize > 0 && typeList.map((type)=>{
                      return(
                        <>
                        {catalog[view][type].map(analys => {
                          return(
                            analys.checked ? 
                            <div className={`analys ${type}`}>
                              <QAIcon className="icon" onClick={()=>{openQA('tf', analys.link)}}/>
                              <div className="name" title={analys.name}>{analys.name}</div>
                              <div className="button-inner" onClick={()=>switchAnalys(view, type, analys.name, false)}>
                                <CloseIcon className="close-icon"/>
                              </div>
                            </div> : <></>
                          )
                        })}
                        </>
                      )
                      })
                    }
                    {selectedSize === 0 && <div className='no-selected'>Нет выбранных анализов</div>}
                    </div>
                  </div>
                  <div className="button" onClick={()=> clearAll()}>{content.Form.buttonClose}</div>
                </div>
                <div className="form-data">
                    <div className="info">{content.Form.info}</div>
                    <div className="line"></div>
                    <div className="dataBlock__column_left">

                    <div className="point">Ваше имя:</div>
                    <input id="analysName" type="text" placeholder="Иван Иванов" />

                    <div className="point">Email:</div>
                    <input id="analysEmail" className="mail-input" type="text" placeholder="info@gmail.com" />

                    <div className="point">Телефон:</div>
                    <input id="analysPhone" type="text" placeholder="+7 (999) 999-99-99" />

                    <div className="send-button" onClick={() => sendMail()}>
                      <div className="loading"></div>
                        Отправить
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Cold"></div>
      {selectedSize > 0 && <div className="selectedPush" onClick={()=>goToForm()}>
        {selectedSize === 1 ? 'Выбран': 'Выбрано'}{' '}
        {selectedSize}{' '}
        {declensionsFromQuantity(selectedSize, ['анализ', 'анализа', 'анализов'])}
      </div>}
    </div>
  )
}
