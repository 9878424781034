export const plantContentMoim1 = {
  helmet: {
    title: 'Маслоочистительная установка 1 м3/ч - Промтех',
    description: 'Высокоэффективная цеолитовая установка для сушки трансформаторного масла обеспечивает его долговечность и надежность. Закажите маслоочистительную установку для профессионального ухода за оборудованием.'
  },
  Init: {
    title: 'Цеолитовая установка 1 м3/ч',
    text: `В установках данной серии используется вакуумная осушка для удаления дисперсной и растворенной воды и газов, и объемная фильтрация на основе отечественных фторопластовых фильтроэлементов`,
    points: [
      'Производительность до 1 м3/ч',
      'Потребляемая мощность до 5 кВТ',
      'Габариты 650x400x530',
      'Вес до 120 кг'
    ]
  },
  Property: {
    title: 'Ключевые качества',
    properties: [
      'Производительность модулей дегазации и фильтрации',
      'Получаемый класс промышленной частоты масла',
      'Отечественные комплектующие для модулей'
    ]
  },
  Character: {
     title: 'Технические характеристики',
     table1: {
      title: 'Показатели установки',
      columns: [
        '№ п/п',
        'Наименование',
        'Единица измерения',
        'Значение'
      ],
      mainRows: [
        [
          'Метод очистки масел',
          '—',
          `Механическая фильтрация, вакуумное испарение`
        ],
        [
          'Очищаемые жидкости',
          '—',
          `Трансформаторные масла, турбинные 
            минеральные и синтетические масла, индустриальные масла`
        ],
        [
          'Максимальная производительность',
          'м3/ч',
          `1`
        ],
        [
          'Максимально допустимое рабочее давление на выходе',
          'МПа',
          `0,3`
        ]
      ],
      subRows: [
        [
          'Минимальное давление на входе ',
          'МПа',
          `0,03`
        ],
        [
          'Максимально допустимое давление на входе',
          'МПа',
          `0,15`
        ],
        [
          'Напряжение питания',
          'В',
          `380`
        ],
        [
          'Класс безопасности по НП-001-15',
          '—',
          `4Н`
        ],
        [
          'Вид климатического исполнения установки по ГОСТ  15150  ',
          '—',
          `УХЛ4`
        ],
      ]

     },
     table2: {
      title: 'Сертификация итогов',
      columns: [
        '№ п/п',
        'Наименование',
        'Единица измерения',
        'Значение'
      ],
      mainRows: [
        [
          'Класс промышленной чистоты масла на выходе, не более',
          '№',
          `8`
        ],
        [
          'Степень очистки при частицах более 5 мкм ',
          '%',
          `100`
        ],
        [
          'Степень очистки при частицах от 3 до 5 мкм',
          '%',
          `80`
        ],
      ],
      subRows: [
        [
          'Максимальное влагосодержание жидкости на входе по ГОСТ 17216 при 20°С (Трансформаторное масло)',
          'г/т',
          `1500`
        ],
        [
          'Содержание воды на выходе установки за один проход при 20°С (Трансформаторное масло)',
          'г/т',
          `100`
        ],
        [
          'Максимальное влагосодержание жидкости на входе по ГОСТ 17216 при 20°С (Турбинное масло)',
          'г/т',
          `100`
        ],
        [
          'Содержание воды на выходе установки за один проход при 20°С (Трансформаторное масло)',
          'г/т',
          `10`
        ],
        [
          'Содержание газов в масле на выходе,не более',
          '%',
          `0,05`
        ],
      ]

     }
  }
}