export const catalogDefault = {
  tf: {
    base: [
      {name: 'Класс промышленной чистоты, по ГОСТ 17216-2001', link: 'cleanClass', checked: false},
      {name: 'Пробивное напряжение, по ГОСТ 6581-75', link: 'voltage', checked: false},
      {name: 'Влагосодержание, по ГОСТ Р МЭК 60814', link: 'moisture', checked: false},
      {name: 'Газосодержание, по РД 34.43.107-95', link: 'gaz', checked: false},
      {name: 'Хроматографический анализ газов, растворенных в масле, по РД 34.46.303-98', link: 'gaz', checked: false},
    ],
    full: [
      {name: 'Тангенс угла диэлектрических потерь масла, по ГОСТ 6581-75', link: 'tg', checked: false},
      {name: 'Кислотное число, по ГОСТ 5985-79', link: 'acid', checked: false},
      {name: 'Содержание водорастворимых кислот и щелочей, по ГОСТ 6307-75', link: 'acidContent', checked: false},
      {name: 'Содержание растворимого шлама, по СТО 70238424.27.100.053-2013', link: 'cleanClass', checked: false},
    ],
    more: [
      // {name: 'Температура застывания (ГОСТ 20287)', link: 'pourPoint', checked: false},
      {name: 'Вязкость кинематическая, по ГОСТ 33-2016', link: 'viscosity', checked: false},
      {name: 'Плотность, по ГОСТ 3900-2022', link: 'cleanClass', checked: false},
      // {name: 'Реакция водной вытяжки (ГОСТ 2706.7)', link: 'waterExtract', checked: false},
      {name: 'Температура вспышки в закрытом тигле, по ГОСТ 6356-75', link: 'tigle', checked: false},
      // {name: 'Температура вспышки в открытом тигле (ГОСТ 4333)', link: 'tigle', checked: false},
      {name: 'Содержание антиокислительной присадки АГИДОЛ-1 (ГХ)', link: 'cleanClass', checked: false},
      {name: 'Определение содержания фурановых производных,по ГОСТ Р МЭК 61198', link: 'cleanClass', checked: false},
      {name: 'Определение массовой доли потерь при высушивании силикагеля, по ГОСТ 3956-76', link: 'cleanClass', checked: false},
    ],
  }, 
  gydr: {
    base: [
      {name: 'Класс промышленной чистоты (ГОСТ 17216)', link: 'cleanClass', checked: false},
      {name: 'Влагосодержание (%, ГОСТ 2477)', link: 'moisture', checked: false},
      {name: 'Газосодержание (%, РД 34.46.107-95)', link: 'gaz', checked: false},
    ],
    full: [
      {name: 'Кислотное число (мг*KOH/г, ГОСТ 11362)', link: 'acid', checked: false},
      {name: 'Время деэмульсации (ГОСТ 12068-66)', link: 'demuls', checked: false},
      {name: 'Содержание водорастворимых кислот и щелочей', link: 'acidContent', checked: false},
    ],
    more: [
      {name: 'Температура застывания (ГОСТ 20287)', link: 'pourPoint', checked: false},
      {name: 'Индекс вязкости (ГОСТ 25371)', link: 'viscosity', checked: false},
      {name: 'Реакция водной вытяжки (ГОСТ 2706.7)', link: 'waterExtract', checked: false},
      {name: 'Температура вспышки в закрытом тигле (ГОСТ 2719)', link: 'tigle', checked: false},
      {name: 'Температура вспышки в открытом тигле (ГОСТ 4333)', link: 'tigle', checked: false},
    ],
  },
  turb: {
    base: [
      {name: 'Класс промышленной чистоты (ГОСТ 17216)', link: 'cleanClass', checked: false},
      {name: 'Влагосодержание (%, ГОСТ 2477)', link: 'moisture', checked: false},
      {name: 'Газосодержание (%, РД 34.46.107-95)', link: 'gaz', checked: false},
    ],
    full: [
      {name: 'Кислотное число (мг*KOH/г, ГОСТ 11362)', link: 'acid', checked: false},
      {name: 'Время деэмульсации (ГОСТ 12068-66)', link: 'demuls', checked: false},
      {name: 'Содержание водорастворимых кислот и щелочей', link: 'acidContent', checked: false},
    ],
    more: [
      {name: 'Температура застывания (ГОСТ 20287)', link: 'pourPoint', checked: false},
      {name: 'Индекс вязкости (ГОСТ 25371)', link: 'viscosity', checked: false},
      {name: 'Реакция водной вытяжки (ГОСТ 2706.7)', link: 'waterExtract', checked: false},
      {name: 'Температура вспышки в закрытом тигле (ГОСТ 2719)', link: 'tigle', checked: false},
      {name: 'Температура вспышки в открытом тигле (ГОСТ 4333)', link: 'tigle', checked: false},
    ],
  }
}