export const companyContent = {
  helmet: {
    title: 'Компания по очистке трансформаторных масел - Промтех',
    description: 'Компания ПРОМТЕХ выполняет работы по очистке, сушке и дегазации трансформаторных и турбинных масел по всей России',
    canonical: 'https://promtech-company.ru/company/'
  },
  about: {
    title: 'О компании',
    text1: `Наша компания является членом группы компаний "Промтех",
        занимающейся разработкой, производством и внедрением оборудования по
        сверхглубокой очистке и регенерации энергетических и промышленных масел`,
    text2: `ООО «Компания ПРОМТЕХ» была  создана с целью выполнения выездных работ по восстановлению масел. Офис находится на территории АО "ТВЭЛ"`
  },
  history: {
    title: 'Развитие компании'
  },
  clients: {
    title: 'Клиенты',
    text: `Основу клиентской базы составляют генерирующие и сетевые компании в
          области электроэнергетики. Основные объекты - предприятия ПАО "Россети", АЭС, ТЭЦ, ГЭС и др.
          Также проводятся работы на промышленных предприятиях и заводах`,
    clientsAll: [
      {
        id: 'Service',
        name: 'Регенерация и очистка',
        types: [
          {
            name: 'visible',
            list: [
              {
                name: 'ПАО Россети Кубань',
                info: `Очистка эксплуатационного трансформаторного масла на трех подстанциях`,
                img: 'service/kuban.webp',
                file: 'ПАО_РОССЕТИ_Кубань_ноябрь_2022.pdf'
              },
              {
                name: 'НИЦ "Курчатовский институт"',
                info: `Осушка и дегазация эксплуатационного трансформаторного масла`,
                img: 'service/kurchatovskiy.jpg',
                file: 'НИЦ_Курчатовский_институт_октябрь_2022.pdf'
              },
              {
                name: 'ПАО "МРСК Центра и Приволжья" - филиал ТУЛЭНЕРГО',
                info: `Регенерация трансформаторного масла с акцентом на улучшение кислотного числа`,
                img: 'service/tula.jpg',
                file: '2021.09.15.Отчет_исп._МУРМ_ТУЛЭНЕРГО.pdf'
              },
            ]
          },
          {
            name: 'hidden',
            list: [
              {
                name: 'Россети ФСК ЕЭС ПС-500кв Пахра',
                info: `Регенерация трансформаторного масла с целью улучшения тангенса угла диэлектрических потерь`,
                img: 'service/domodedovo.jpg',
                file: '2021.09.21.Отчет_исп.МУРМ_Пахра_МЭС_Центра_с_прилож.pdf'
              },
              {
                name: 'АО Завод РЭТО',
                info: `Регенерация отработанного трансформаторного масла с добавлением присадок`,
                img: 'service/reto.jpg',
                file: 'АО_Завод_РЭТО.pdf'
              },
            ]
          }
        ]
      },
      // {
      //   id: 'Plants',
      //   name: 'Поставка оборудования',
      //   types: [
      //     {
      //       name: 'visible',
      //       list: [
      //         {
      //           name: 'ДГК "Приморская генерация"',
      //           info: `Поставлена и введена в эксплуатацию установка
      //                 по очистке и регенерации масла`,
      //           img: 'plants/DGK_Prim.jpg'
      //         },
      //         {
      //           name: 'ДГК "Хабаровская генерация"',
      //           info: `Поставлена и введена в эксплуатацию установка 
      //                 по очистке и регенерации масла`,
      //           img: 'plants/DGK_Hab.jpg'
      //         },
      //         {
      //           name: 'Смоленская АЭС',
      //           info: `Поставлена и введена в эксплуатацию  установка
      //                 по электрофизической очистке`,
      //           img: 'plants/Smol.jpg'
      //         },
      //       ]
      //     },
      //     {
      //       name: 'hidden',
      //       list: [
      //         {
      //           name: 'ДГК "Нюрингринская ГРЭС"',
      //           info: `Поставлена и введена в эксплуатацию установка 
      //                 по очистке и регенерации масла`,
      //           img: 'plants/DGK_Nyr.jpg'
      //         },
      //         {
      //           name: 'ДГК "Амурская генерация"',
      //           info: `Поставлена и введена в эксплуатацию установка
      //                 по очистке и регенерации масла`,
      //           img: 'plants/DGK_Amur.jpg'
      //         },
      //         {
      //           name: 'Нижегородская ПМЭС',
      //           info: `Поставлена и введена в эксплуацию установка 
      //                 , выполняющая функции очистки`,
      //           img: 'plants/PMES.jpg'
      //         },
      //       ]
      //     }
      //   ]
      // },
    ]
  },
  thanks: {
    title: 'Доверие и компетенции',
    list: [
      {
        img: 'thank-Kuban.png',
        url: 'Благодарность_Кубань.pdf'
      },
      {
        img: 'thank-Kurchatovskiy.png',
        url: 'Благодарность_Курчатовский.pdf'
      },
      {
        img: 'thank-DGK.jpg',
        url: 'Отзыв_ДГК.pdf'
      },
      {
        img: 'thank-Sozidanie.jpg',
        url: 'Отзыв_Созидание.pdf'
      }
    ]
  }
}