import React from "react";
import Slider from "react-slick";
import arrow from '../../media/icons/arrow_white.svg'
import './company.style.scss'
import { HashLink } from 'react-router-hash-link';


function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="slider__button_left" onClick={onClick}>
            <img src={arrow} alt="" className="slider__button_arrow arrow_left" ></img>
        </div>
    );
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="slider__button_right" onClick={onClick}>
            <img src={arrow} alt="" className="slider__button_arrow" ></img>
        </div>
    );
}


function opacity(next) {

    let slides = document.querySelectorAll('.slider__event')

    if (next == 0) {
        slides[0].style.opacity = 1;
        slides[1].style.opacity = 0.5;
        slides[2].style.opacity = 0.2;
        slides[3].style.opacity = 0;
        slides[4].style.opacity = 0;
    }
    if (next == 1) {
        slides[0].style.opacity = 0;
        slides[1].style.opacity = 1;
        slides[2].style.opacity = 0.5;
        slides[3].style.opacity = 0.2;
        slides[4].style.opacity = 0;

    }
    if (next == 2) {
        slides[0].style.opacity = 0;
        slides[1].style.opacity = 0;
        slides[2].style.opacity = 1;
        slides[3].style.opacity = 0.5;
        slides[4].style.opacity = 0.2;
    }
    if (next == 3) {
        slides[0].style.opacity = 0;
        slides[1].style.opacity = 0;
        slides[2].style.opacity = 0;
        slides[3].style.opacity = 1;
        slides[4].style.opacity = 0.5;
    }
    if (next == 4) {
        slides[0].style.opacity = 0;
        slides[1].style.opacity = 0;
        slides[2].style.opacity = 0;
        slides[3].style.opacity = 0;
        slides[4].style.opacity = 1;
    }

}


export default function CompanySlider() {
    setTimeout(() => {
        let slides = document.querySelectorAll('.slider__event')
        slides[0].style.opacity = 1;
        slides[1].style.opacity = 0.5;
        slides[2].style.opacity = 0.2;
        slides[3].style.opacity = 0.2;
        slides[4].style.opacity = 0.2;
        slides[5].style.opacity = 0;
        slides[6].style.opacity = 0;
    }, 300);
    var settings = {
        slidesToShow: 3,
        speed: 1000,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        beforeChange: (current, next) => { opacity(next) },
        // beforeChange: (current, next) => {alert(next)},
        dots: false,
        infinite: false,
        focusOnSelect: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };



    return (
        <Slider className="slider__sliderBody" {...settings}>

            <div className="slider__event">
                <div className="event__title"><h2>Инициализация</h2></div>
                <div className="event__text">
                    <p>Привлечены инвестиции для реализации удаленного
                        выполнения работ по восстановлению индустриальных масел.
                        Был разработан мобильный комплекс на базе автомобиля Камаз,
                        включающий в себя установку регенерации и
                        встроенную химическую лабораторию</p>
                </div>
                <div className="event__date"><p>2019-2020 гг.</p></div>
            </div>
            <div className="slider__event">
                <div className="event__title"><h2>Первые работы</h2></div>
                <div className="event__text">
                    <p>Успешно проведены экспериментальные
                        работы по восстановлению физико-химических свойств эксплуатационного трансформаторного масла
                        на объектах ПАО "МРСК Центра и Приволжья" - филиал ТУЛЭНЕРГО и Россети ФСК ЕЭС ПС-500кв Пахра</p>
                </div>
                <div className="event__date"><p>Осень 2021 г.</p></div>
            </div>
            <div className="slider__event">
                <div className="event__title"><h2>Развитие технологии </h2></div>
                <div className="event__text">
                    <p>После проведенных работ руководством компании было принято решение по увеличению
                        производительности комплекса путем
                        внедрения в него установки очистки 4 м3/ч. В результате
                        чего общая производительность процесса очистки
                        достигла 6 м3/ч </p>
                </div>
                <div className="event__date"><p>Осень – зима 2021 г.</p></div>
            </div>
            <div className="slider__event">
                <div className="event__title"><h2> Опыт эксплуатации </h2></div>
                <div className="event__text">
                    <p>Проведены полноценные работы
                        по очистке и регенерации эксплуатационного трансформаторного
                        масла на объектах НИЦ «Курчатовский институт»
                        и трех подстанциях ПАО Россети Кубань: «Южная»,
                        «Нововеличковская» и «Водохранилище» и предоставлены результаты
                        сертифицированных анализов
                    </p>
                </div>
                <div className="event__date"><p>лето – осень 2022 г.</p></div>
            </div>
            <div className="slider__event">
                <div className="event__title"><h2>На текущий момент  </h2></div>
                <div className="event__text">
                    <p>Компания продолжает выполнение удаленных работ
                        по восстановлению трансформаторных и турбинных
                        масел, усердно развивая сотрудничество с
                        энергетическими и сетевыми компаниями, а также производственными
                        предприятиями внутри нашей страны
                    </p>
                </div>
                <div className="event__date"><p>осень 2022 г. - н.в.</p></div>
            </div>
            <div className="slider__event">
                <div className="event__title"><h2>Образец слайда  </h2></div>
                <div className="event__text">
                    <p>Образец текста</p>
                </div>
                <div className="event__date"><p>Осень – зима 2020 г.</p></div>
            </div>
            <div className="slider__event">
                <div className="event__title"><h2>Образец слайда  </h2></div>
                <div className="event__text">
                    <p>Образец текста</p>
                </div>
                <div className="event__date"><p>Осень – зима 2020 г.</p></div>
            </div>



        </Slider>

    );
}


