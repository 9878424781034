export const homeContent = {
  helmet: {
    title: 'Очистка, сушка и дегазация трансформаторного масла - Промтех',
    description: 'Выполнение работ по очистке, сушке и дегазации трансформаторных масел на объекте заказчика по всей России',
    canonical: 'https://promtech-company.ru/'
  },
  main: {
    tezis: `Применение технологий для восстановления и сохранения свойств 
            энергетических и промышленных масел`,
  },
  activity: {
    title: 'Деятельность компании',
    text1: `Проведение работ по восстановлению физико-химических 
            свойств <span>трансформаторного</span> и <span>турбинного</span> масел при помощи собственных 
            установок очистки и регенерации в соответствии с принятыми в генерирующих 
            и сетевых компаниях нормативными документами`,
    text2: `При проведении работ существует два подхода: базовый и 
            полный цикл восстановления. Выбор зависит от текущего состояния масла и необходимого результата`
  },
  cycles:{
    basic: {
      title: 'Базовая очистка масла',
      operations: [
        'Вакуумная осушка',
        'Фильтрация от механических примесей',
        'Дегазация'
      ],
      info: `Рекомендуется проводить регулярно в рамках
             технического обслуживания оборудования с 
             целью поддержания свойств эксплуатационного масла`
    },
    full: {
      title: 'Полная регенерация масла',
      operations: [
        'Все операции базовой очистки',
        'Регенерация через сорбент',
        'Ввод стабилизирующих присадок',
        'Итоговая осушка'
      ],
      info: `Восстановление физико-химических свойств 
             до состояния на момент покупки, проводится в случае
             непригодного состояния эксплуатационного масла`
      },
  },
  qualities: {
    title: 'Показатели качества',
    switch: ['Трансформаторы','Турбины'],
    views: [
      {
        title: 'Трансформаторное масло',
        info: `Проводим работы на трансформаторах разнообразных
               типов и годов выпуска, преимущественно средней и
               высокой мощности с минимальным общим объемом от 5 тонн`,
        img: 'transformator.png',
        table: [
            {
              title: 'Изменяемый параметр',
              info: [
                'Содержание механических примесей', 
                'Максимальная влажность',
                'Содержание газов',
                'Тангенс угла диэлектрических потерь',
                'Пробойное напряжение'
              ]
            },
            {
              title: 'Получаемое качество',
              info: [
                'Меньше  <span>0.05% </span>', 
                '<span>100</span> г/т',
                'Не более <span>0.05%</span>',
                'Не более <span>1.5</span>',
                'Не менее <span>65</span> кВ'
              ]
            }
        ]
      },
      {
        title: 'Турбинное масло',
        info: `Работы с турбинами ведутся на генерирующих 
               объектах, включая ТЭЦ, АЭС, ГЭС, а также турбинами,
               используемыми на промышленных предприятиях`,
        img: 'turbina.png',
        table: [
            {
              title: 'Изменяемый параметр',
              info: [
                'Содержание механических примесей', 
                'Максимальная влажность',
                'Содержание газов',
                'Время деэмульсации',
                'Кислотное число'
              ]
            },
            {
              title: 'Получаемое качество',
              info: [
                'Меньше <span>0.01%</span>', 
                '<span>10</span> г/т',
                'Не более <span>0.08%</span>',
                'От <span>100</span> до <span>250</span> секунд',
                'Не более <span>0,15</span> мг КОН/г'
              ]
            }
          ]
      }
    ]
  },
  calculator:{
    title: 'Стоимость работ',
    info: `Выставив нижеуказанные параметры и значения, вы сможете 
          узнать примерную оценку затрат на проведение работ на 
          вашем объекте, показанную в процентном соотношении 
          в сравнении с покупкой нового масла`,
    calculating: {
      title1: 'Вид работы',
      title2: 'Исходный класс чистоты',
      distance: 'Удаленность от Москвы',
      volume: 'Объем масла'
    },
    result:{
      title: 'Наиболее вероятная стоимость:',
      info: 'от покупки нового масла',
      button: 'Отправить заявку'
    }
  },
  plants: {
    title: 'Установки',
    text1: `В случае удаленности от Москвы более
            чем в 3000 км или наличием регулярных
            объемов эксплуатационного масла,
            требующего поддержания свойств,
            рекомендуем ознакомиться с 
            каталогом установок`,
    text2: 'Актуально при выполнении перечня условий:',
    points: [
      'Более 3000 км от Москвы',
      'От 100 тонн ежегодно',
      'От 5-7 эксплуатируемых объектов'
    ]
  }
}