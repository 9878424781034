export const technoContent = {
  helmet: {
    title: 'Фильтрация и восстановление отработанного масла - Промтех',
    description: 'Комплексные услуги по фильтрации, очистке и восстановлению отработанных масел. Обеспечивая повторного использования масла с сохранением первоначальных свойств',
    canonical: 'https://promtech-company.ru/techno/'
  },
  about: {
    title: 'Восстановление отработанного масла ',
    text1: `Существует порядка десяти запатентованных методов по восстановлению отработанного масла. Но каждый включает в себя ряд
          существенных недостатков. Простые способы очистки, состоящие из комбинации фильтрации, дегазации и осушки,
          дают низкое качество в виду отсутствия адсорбционной обработки. Более сложные технологии не отвечают требованиям
          малоотходности и экологической безопасности - осадок, образующийся после прохода через сорбент, требует последующей утилизации в
          качестве экологически опасного отхода. Данные недостатки были учтены в нашей разработке.`,
    tezis: `Из индустриальных масел мы восстанавливаем трансформаторные, 
            турбинные и компрессорные масла`,
    text2: `Наша технология включает регенерацию отработанного энергетического масла, обеспечивающую удаление механических примесей,
          подогрев, вакуумную сушку и дегазацию, адсорбционную обработку и последующий ввод базового пакета присадок.`,
    text3: `Преимущество нашей технологии - повышение качества регенерированного масла за счет более полного извлечения продуктов старения из
          отработанного масла при сохранении экологичности.`
  },
  process: {
    title: 'Описание технологического процесса',
    text: `Работы по восстановлению индустриального масла начинаются с изучения
        протоколов физико-химических показателей представленного масла, после чего нами берется проба с
        объекта заказчика для проведения собственного анализа. По его результатам принимается решение: выполнять полный 
        цикл восстановления, включающий очистку и регенерацию или ограничиться только очисткой.`,
    below: 'Ниже приведены развернутые этапы операций:'
  }
}