export const serviceContent = {
        
  drying: {
    title: 'Сушка трансформаторного масла - Промтех',
    description: `Сушка трансформаторного масла для обеспечения его оптимальной работы и продления срока службы трансформаторов. Услуги по осушке масла с использованием передовых технологий по доступным ценам.`,
    h1: 'Сушка трансформаторного масла',
    info: `Трансформаторное масло является важным компонентом в системах 
            энергоснабжения, обеспечивая охлаждение и изоляцию трансформаторов. 
            С течением времени, влага может накапливаться в масле, что негативно
             сказывается на его работоспособности и безопасности. 
             Процесс сушки трансформаторного масла помогает 
             устранить эту проблему, тем самым повышая 
             надежность и эффективность работы 
             электрооборудования`,
    aboutTitle: 'Что такое сушка масла',
    about: `Сушка масла – это процесс удаления влаги из трансформаторного масла. Это ключевая процедура, 
            которая предотвращает сокращение срока службы трансформатора и снижение его эффективности. 
            Регулярная осушка масла является неотъемлемой частью технического обслуживания, 
            которая помогает предотвратить аварии и поломки`,
    tableTitle: 'Существующие виды восстановления масла:',
    methodsTitle: 'Выездная осушка масла',
    methods: `Наша компания проводит комплексные работы по сушке трансформаторного масла с использованием собственного маслоочистительного
              оборудование с выездом на объект заказчика. Цена зависит от удаленности и общего объема масла`,
  },
  filtration: {
    title: 'Фильтрация масла от механических примесей - Промтех',
    description: 'Профессиональные услуги по фильтрации и очистке масла с удалением механические примесей и воды для улучшения работы вашего оборудования.',
    h1: 'Фильтрация масла от механических примесей',
    info: `Эффективная фильтрация масла от механических примесей – ключевой аспект поддержания работоспособности и надежности трансформаторов. 
           Мы предоставляем услуги по высококачественной очистке масла, что позволяет предотвратить возможные неисправности и продлить срок службы оборудования.`,
    aboutTitle: 'Важность очистки масла от примесей',
    about: `Очистка масла от механических примесей и воды необходима для обеспечения его диэлектрических свойств и предотвращения коррозии внутренних частей трансформатора. 
            Регулярная очистка улучшает эффективность работы оборудования и является экономически выгодной альтернативой частой замене масла.`,
    tableTitle: 'Технологии очистки трансформаторного масла:',
    methodsTitle: 'Выездная очистка масла',
    methods: `Наша компания осуществляет профессиональную фильтрацию и очистку масла от воды и механических примесей прямо на объекте заказчика. 
              Мы используем современное оборудование, способное эффективно удалять загрязнения, обеспечивая тем самым высочайшее качество очистки.`,
  },  
  degasification: {
    title: 'Дегазация трансформаторного масла - Промтех',
    description: 'Обеспечьте безопасность и эффективность трансформаторов с профессиональной дегазацией масла. Предлагаем доступные расценки на услуги дегазации трансформаторного масла для оптимизации его работы.',
    h1: 'Дегазация трансформаторного масла',
    info: `Дегазация трансформаторного масла – это критически важный процесс, который помогает устранить растворенные газы из масла, 
           тем самым повышая его диэлектрические свойства и предотвращая образование пены и окисления.`,
    aboutTitle: 'Значение процесса дегазации масла для трансформаторов',
    about: `Процесс дегазации масла трансформатора необходим для поддержания и восстановления изоляционных свойств масла и улучшения его производительности. 
            Дегазация позволяет избежать многих распространенных неисправностей трансформаторов, обусловленных присутствием воздуха и газов в масле.`,
    tableTitle: 'Методы дегазации трансформаторного масла:',
    methodsTitle: 'Как мы проводим дегазацию масла',
    methods: `В нашем распоряжении имеется современное оборудование для дегазации трансформаторного масла, 
              что позволяет нам эффективно и быстро проводить эт от процесс на месте эксплуатации трансформатора. Мы предлагаем услуги дегазации как для небольших, так и для больших объемов масла,
              обеспечивая при этом точное соблюдение всех необходимых технологических стандартов.`
  },  
  regeneration: {
    title: 'Регенерация трансформаторного масла - Промтех',
    description: 'Эффективная регенерация и восстановление трансформаторного масла для улучшения его эксплуатационных качеств и увеличения срока службы трансформаторов. Качественные услуги по доступной цене.',
    h1: 'Регенерация трансформаторного масла',
    info: `Трансформаторное масло, используемое в качестве изолятора и охлаждающей жидкости, со временем теряет свои качества. 
           Процесс регенерации масла трансформаторного позволяет восстановить его первоначальные характеристики, 
           улучшая тем самым производительность и надежность трансформаторов.`,
    aboutTitle: 'Что такое регенерация масла в трансформаторах',
    about: `Регенерация масла в трансформаторах – это технологический процесс, направленный на восстановление 
            эксплуатационных свойств масла путем удаления продуктов старения и загрязнений. 
            Этот метод является альтернативой замене масла и способствует продлению срока службы как масла, так и трансформаторного оборудования.`,
    tableTitle: 'Существующие виды восстановления масла:',
    methodsTitle: 'Выездная регенерация масла:',
    methods: `Мы предлагаем услуги по регенерации масла трансформаторного непосредственно на объекте заказчика. 
              Наше оборудование позволяет проводить восстановление масла с максимальной эффективностью, 
              что существенно снижает затраты по сравнению с полной заменой масла. Стоимость услуги 
              зависит от объема и состояния масла, а также от удаленности объекта.`,
  },
  cleaning: {
    title: 'Очистка трансформаторного масла - Промтех',
    description: `Очистка трансформаторного масла для обеспечения его оптимальной работы и продления срока службы трансформаторов. Услуги по очистке масла с использованием современных технологий по доступным ценам.`,
    h1: 'Очистка трансформаторного масла',
    info: `Трансформаторное масло играет ключевую роль в обеспечении работы электрооборудования, выполняя функции охлаждения и изоляции. Со временем в масле могут скапливаться примеси и загрязнения, что ухудшает его функциональные характеристики и может привести к авариям. Процесс очистки трансформаторного масла направлен на устранение этих проблем и повышение безопасности и эффективности работы оборудования.`,
    aboutTitle: 'Что такое очистка масла',
    about: `Очистка трансформаторного масла — это процесс удаления загрязнений и восстановления его первоначальных характеристик. Эта процедура критически важна для поддержания эффективности и долговечности трансформаторов. Регулярная очистка масла является частью обязательного обслуживания, которое предотвращает многие технические неисправности.`,
    tableTitle: 'Методы восстановления качества трансформаторного масла:',
    methodsTitle: 'Мобильная очистка трансформаторного масла',
    methods: `Наша компания предлагает услуги по очистке трансформаторного масла непосредственно на месте у заказчика. Мы используем передовое маслоочистительное оборудование, что позволяет обеспечить высокое качество услуг. Стоимость услуг зависит от местоположения объекта и объема обрабатываемого масла.`,
  },
  replacement: {
    title: 'Замена трансформаторного масла в силовых трансформаторах - Промтех',
    description: 'Замена трансформаторного масла с гарантией качества для силовых трансформаторов. Профессиональные услуги по замене масла в трансформаторах, обеспечивающие надежность и долговечность оборудования.',
    h1: 'Замена трансформаторного масла',
    info: `Замена трансформаторного масла – это критически важная процедура, необходимая для поддержания эффективности и безопасности силовых трансформаторов. 
           Наша компания предлагает полный спектр услуг по замене масла с гарантией качества и надежности.`,
    aboutTitle: 'Процесс замены масла в трансформаторе',
    about: `Регулярная замена масла в трансформаторе предотвращает многие неисправности и продлевает срок службы оборудования. 
            Мы используем только высококачественное масло, что обеспечивает оптимальную работу трансформатора и предотвращает износ компонентов.`,
    tableTitle: 'Также проводим восстановление масла:',
    methodsTitle: 'Стоимость замены трансформаторного масла',
    methods: `Цена на замену трансформаторного масла зависит от объема трансформатора, типа масла и условий работы. 
              Мы предлагаем конкурентные расценки и высокое качество услуг по замене масла в силовых трансформаторах, 
              обеспечивая надежность и долговечность вашего оборудования.`,
  },

  filling: {
    title: 'Заливка масла в трансформатор - Промтех',
    description: 'Профессиональная заливка и доливка масла в трансформаторы для обеспечения стабильной и безопасной эксплуатации силовых установок. Надежные услуги поддержания оптимального уровня масла в вашем оборудовании.',
    h1: 'Заливка и доливка масла в трансформаторы',
    info: `Правильная заливка масла в трансформаторы является ключевым условием их надежной и бесперебойной работы. 
           Мы предлагаем услуги по заливке и доливке трансформаторного масла, что обеспечивает долгосрочную стабильность и эффективность силовых трансформаторов.`,
    aboutTitle: 'Значение качественной заливки масла',
    about: `Качественная заливка и своевременная доливка масла в силовые трансформаторы обеспечивают их корректное функционирование и предотвращают различные неисправности. 
            Наша команда специалистов осуществляет эти процедуры с применением современного оборудования и строгим соблюдением технологических норм.`,
    tableTitle: 'Также проводим восстановление масла:',
    methodsTitle: 'Услуги по заливке и доливке масла',
    methods: `Мы предлагаем всестороннее обслуживание по заливке и доливке масла в трансформаторы, включая диагностику состояния оборудования и определение оптимального уровня масла. 
              Наши услуги адаптированы под каждый конкретный тип трансформатора, гарантируя тем самым максимальную эффективность работы.`,
  },

  testing: {
    title: 'Испытание трансформаторного масла на пробой - Промтех',
    description: 'Профессиональное испытание трансформаторного масла на пробой поверенным оборудованием на базе сертифицированной лаборатории',
    h1: 'Испытание трансформаторного масла',
    info: `Испытание трансформаторного масла на пробой является критически важным для обеспечения его диэлектрических свойств и надежности трансформаторов. 
           Наш сервис предлагает точные и надежные испытания масла, используя современные методики и оборудование.`,
    aboutTitle: 'Значение испытаний трансформаторного масла',
    about: `Регулярные испытания трансформаторного масла на пробой помогают предотвратить возможные аварии и продлить срок службы оборудования. 
            Мы проводим детальный анализ состояния масла, определяя его способность выдерживать высокие напряжения без пробоя.`,
    tableTitle: 'Также проводим восстановление масла:',
    methodsTitle: 'Цены на испытания трансформаторного масла',
    methods: `Ценообразование наших услуг по испытанию трансформаторного масла зависит от объема образцов и требуемых параметров испытаний. 
              Мы предлагаем конкурентные цены и высокое качество испытаний, обеспечивая точные и надежные результаты для каждого клиента.`,
  },

  hga: {
    title: 'Хроматографический анализ трансформаторного масла - Промтех',
    description: 'Проводим точный хроматографический анализ масла трансформаторов и газов, растворенных в масле на базе сертифицированной лаборатории',
    h1: 'Хроматографический анализ трансформаторного масла',
    info: `Хроматографический анализ трансформаторного масла позволяет точно определить состав газов, растворенных в масле, что является важным индикатором состояния трансформаторов. 
           Мы предоставляем профессиональные услуги по хроматографическому анализу, обеспечивая высокую точность результатов и комплексное понимание состояния вашего оборудования.`,
    aboutTitle: 'Значение хроматографического анализа для трансформаторов',
    about: `Хроматографический анализ масла и растворенных в нем газов позволяет рано выявлять потенциальные неисправности и предотвращать серьезные повреждения оборудования. 
            Этот анализ дает возможность оценить работу трансформатора и спланировать необходимое обслуживание, минимизируя риски отказа.`,
    tableTitle: 'Также проводим восстановление масла:',
    methodsTitle: 'Цены и условия проведения хроматографического анализа',
    methods: `Цена хроматографического анализа трансформаторного масла зависит от сложности и объема необходимых исследований. 
              Мы гарантируем прозрачные условия сотрудничества и предлагаем гибкие тарифы, учитывая специфику каждого заказа и требования клиентов.`,
  }
  
  
}