export const archiveContent = {
  sections: [
    {
      name: 'Научно-техническая документация',
      url: 'НТД',
      files: [
        {
          name: 'РД34.45-51.300-97',
          distUrl: true,
          url: 'https://files.stroyinf.ru/Data1/11/11967/index.htm',
        },
        {
          name: 'СТО 70238424.27.100.053-2013',
          url: 'СТО_70238424.27.100.053-2013.pdf',
        },
        {
          name: 'РД ЭО 1 1 2 05 0444-2016',
          url: '14_11_2016_№9_1459-П_О_введении_в_действие_РД_ЭО_1_1_2_05_0444-2016.pdf',
        },
        {
          name: 'СТО 34.01-23.1-001-2017',
          url: 'ОНИЭ_31_Трансформаторное_масло_Часть_2.pdf',
        },
        {
          name: 'СТО 02.01.112-2015 РусГидро',
          url: 'СТО_02.01.112-2015_РусГидро.pdf',
          nextSection: 'ksor'
        }
      ]
    },
    // {
    //   name: 'Установка регенерации КСОР-1',
    //   url: 'КСОР_1',
    //   files: [
    //     {
    //       name: 'Общий вид установки (вид сверху)',
    //       url: 'Общий_вид_установки_(вид сверху).pdf',
    //     },
    //     {
    //       name: 'Паспорт КСОР',
    //       url: 'Паспорт_КСОР.pdf',
    //     },
    //     {
    //       name: 'Руководство КСОР',
    //       url: 'Руководство_КСОР.pdf',
    //       nextSection: 'moim'
    //     },
    //   ]
    // },
    // {
    //   name: 'Установка очистки МОИМ-4.0',
    //   url: 'МОИМ',
    //   files: [
    //     {
    //       name: 'Основные характеристики МОИМ-4.0',
    //       url: 'Основные_характеристики_МОИМ-4.0.pdf',
    //     },
    //     {
    //       name: 'Схема установки МОИМ-4.0',
    //       url: 'Схема_установки_МОИМ-4.0.pdf',
    //       nextSection: 'docs'
    //     },
    //   ]
    // },
    // {
    //   name: 'Отчетная документация',
    //   url: 'Отчеты',
    //   files: [
    //     {
    //       name: 'ПАО РОССЕТИ Кубань ноябрь 2022',
    //       url: 'ПАО_РОССЕТИ_Кубань_ноябрь_2022.pdf',
    //     },
    //     {
    //       name: 'НИЦ Курчатовский институт октябрь 2022',
    //       url: 'НИЦ_Курчатовский_институт_октябрь_2022.pdf',
    //     },
    //     {
    //       name: '2021.09.15.Отчет исп. МУРМ ТУЛЭНЕРГО',
    //       url: '2021.09.15.Отчет_исп._МУРМ_ТУЛЭНЕРГО.pdf',
    //     },
    //     {
    //       name: '2021.09.21.Отчет исп.МУРМ Пахра МЭС Центра с прилож',
    //       url: '2021.09.21.Отчет_исп.МУРМ_Пахра_МЭС_Центра_с_прилож.pdf',
    //     },
    //     {
    //       name: 'Результаты АО Завод РЭТО',
    //       url: 'АО_Завод_РЭТО.pdf',
    //       nextSection: 'thanks'
    //     },
    //   ]
    // },
    // {
    //   name: 'Благодарности и отзывы',
    //   url: 'Благодарности',
    //   files: [
    //     {
    //       name: 'Благодарность Кубань',
    //       url: 'Благодарность_Кубань.pdf',
    //     },
    //     {
    //       name: 'Благодарность Курчатовский',
    //       url: 'Благодарность_Курчатовский.pdf',
    //     },
    //     {
    //       name: 'Отзыв ДГК',
    //       url: 'Отзыв_ДГК.pdf',
    //     },
    //     {
    //       name: 'Отзыв ДГК Хабаровская генерация',
    //       url: 'Отзыв_ДГК_Хабаровская_генерация.pdf',
    //     },
    //     {
    //       name: 'Отзыв Созидание',
    //       url: 'Отзыв_Созидание.pdf',
    //     },
    //     {
    //       name: 'Письмо главным инженерам Россети',
    //       url: 'Письмо_главным_инженерам_Россети.pdf',
    //       nextSection: 'patents'
    //     },
    //   ]
    // },
    // {
    //   name: 'Патентная документация',
    //   url: 'Патенты',
    //   files: [
    //     {
    //       name: 'Патент-свидетельство 2679901',
    //       url: 'Патент-свидетельство_2679901.pdf',
    //     },
    //     {
    //       name: 'Патент масло турбинное 2641005',
    //       url: 'Патент_масло_турбинное_2641005.pdf',
    //     },
    //     {
    //       name: 'Описание патента 2679901',
    //       url: 'Описание_патента_2679901.pdf',
    //     },
    //     {
    //       name: 'Описание патента 2641005',
    //       url: 'Описание_патента_2641005.pdf',
    //     },
    //   ]
    // }
  ]
}