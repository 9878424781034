import React, {useEffect} from 'react'
import './new-service.style.scss'
import { newServiceContent as content} from './new-service.content';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export const NewService = (props) => {
  window.scrollTo(0, 0);
  function showFooter() {
    document.querySelector('.footer').style.display = "flex"
  }
  setTimeout(() => {
    showFooter()
  }, 300);

  const calc = (val) => {
    if (document.documentElement.clientWidth < 1920) {
      return val * 1024 / 1920 + (val - val * 1024 / 1920) * ((document.documentElement.clientWidth - 1024) / (1920 - 1024))
    } else return val
  }



  return (
    <div className="NewService">
      <Helmet>
        <title>{content[props.page].title}</title>
        <meta name="description" content={content[props.page].description} />
      </Helmet>
      <div className="Warm">
        <div className="content__container">
          <div className="Warm-body">
            <div className={`${props.type === 'trans' ? 'blue-blot' : 'orange-blot'}`}><img src={require(`${props.type === 'trans' ? './media/blot-blue.png' : './media/blot-orange.png'}`)} alt="" /></div>
            <div className="About">
              <div className="about-title wow animate__animated animate__fadeIn" data-wow-duration="0.8s"><h1>{content[props.page].h1}</h1></div>
              <div className="about-info wow animate__animated animate__fadeIn" data-wow-duration="0.8s">
                <div className="info-text">
                  <p>{content[props.page].info1}</p>
                  <p>{content[props.page].info2}</p>
                  {/* <p>{content.About.text2}</p> */}
                </div>
                <div className="info-img">
                  <img src={require(`./media/${content[props.page].img}`)} alt="" />
                </div>
              </div>
            </div>
            <div className="List">
              <h2>{content[props.page].list.title}</h2>
              {/* <p>{content[props.page].list.info}</p> */}
              <div className="steps">
                {content[props.page].list.steps.map(step=>{
                  return(
                    <div className={`step ${props.type === 'trans' ? 'step-blue' : 'step-orange'}`} dangerouslySetInnerHTML={{ __html: step }}></div>
                  )
                })}
              </div>
            </div>
            <div className="Final">
              <h2>{content[props.page].final.title}</h2>
              {content[props.page].final.info.map(info_ex=>{
                  return(
                    <p className="info_ex">{info_ex}</p>
                  )
                })}

            </div>
            <div className="request">
              <div className="request-info">Если вам необходим данный вид услуги, вы можете связаться с нами для обсуждения проведения работ</div>
              <div className="request-button" onClick={props.showForm}>Запросить коммерческое предложение</div>
            </div> 
           
            
           
            
          </div>
        </div>
      </div>
      <div className="Cold">
          <div className="SameServices">
              <h2>{content[props.page].sameServices.title}</h2>
              <div className="services-list">
                {content[props.page].sameServices.list.map(service=>{
                  return(
                    <Link to={props.type === 'trans' ? `../trans/${service.href}` : `../services/${service.href}`} className="service-item">
                      <div className="service-img"><img src={require(`./media/${service.img}`)} alt="" /></div>
                      <div className="service-name">{service.name}</div>
                    </Link>
                  )
                })}
              </div>
            </div>
      </div>
    </div>
  )
}
